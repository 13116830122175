import React, {useEffect, useState} from "react";
import '../../../styles/Admin.sass'

import {PageActions, PageContainer} from "../../../components/PageContainer";
import {TileAncestor} from "../../../components/Tiles";
import {confirmDelete} from "../../../components/Alert";

import {openEditEventClassForm, openNewEventClassForm} from "./EventClassModals";

import {EventClass} from "../../../model/EventClass";

import {
    addEventClassData,
    deleteEventClassData,
    listenEventClassChanges,
    listEventClasses,
    listLocations,
    saveEventClassData
} from "../../../api/admin";
import {EventClassItem} from "./EventClassItem";
import {Buttons, DeleteButton, EditButton, NewButton} from "../../../components/Buttons";

/*************************
 * API calls
 *************************/
const createEventClassFunc = () => {
    return async (eventClass) => {
        console.log('createEventClassFunc', eventClass);
        if (eventClass.name && eventClass.location) {
            await addEventClassData(eventClass);
        }
    }
}

const saveEventClass = (eventClassId) => {
    return async (eventClassData) => {
        if (eventClassData.name && eventClassData.location) {
            const eventClass = new EventClass(eventClassId, null, eventClassData.name, eventClassData.description, eventClassData.location, eventClassData.slots)
            await saveEventClassData(eventClassId, eventClass);
        }
    }
}

const deleteEventClass = (eventClass) => {
    const messages = [eventClass.name];
    confirmDelete("Biztos törlöd?", messages, async () => {
        return deleteEventClassData(eventClass.id);
    });
}


/*************************
 * Main component
 *************************/
export const EventClassPage = () => {

    /*************************
     * State & Hooks
     *************************/
    const [loading, setLoading] = useState(true);
    const [eventClasses, setEventClasses] = useState([]);
    const [locations, setLocations] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        const fetchEventClasses = async () => {
            setEventClasses(await listEventClasses());
        }

        const fetchLocations = async () => {
            setLocations(await listLocations());
        }

        Promise.all([fetchEventClasses(), fetchLocations()]).then(() => setLoading(false));
    }, [])

    useEffect(() => {
        return loading ? undefined : listenEventClassChanges(setEventClasses);
    }, [loading]);


    /*************************
     * Event Handlers
     *************************/
    const onClickNewClass = () => {
        return openNewEventClassForm(locations, createEventClassFunc());
    }

    const onClickEditClass = () => {
        const eventClass = eventClasses.find(ec => ec.id === selected.id);
        if (eventClass)
            return openEditEventClassForm(eventClass, locations, saveEventClass(eventClass.id));
    }

    const onClickDeleteEventClass = () => {
        deleteEventClass(selected);
    }

    const toggleSelection = (eventClassId) => {
        if (selected && selected.id === eventClassId)
            setSelected(null)
        else
            setSelected(eventClasses.find(ec => ec.id === eventClassId))
    }

    /*************************
     * Renderers
     *************************/
    return (
        <>
            <PageContainer>
                <PageActions title="Csoportok">
                    <Buttons areSmall={false} hasAddons={true} hidden={loading}>
                        <DeleteButton disabled={selected == null} onClick={onClickDeleteEventClass}/>
                        <EditButton disabled={selected == null} onClick={onClickEditClass}/>
                        <NewButton onClick={onClickNewClass}/>
                    </Buttons>
                </PageActions>
                <TileAncestor>
                    {eventClasses
                        .filter(eventClass => eventClass && eventClass.id && eventClass.name)
                        .sort((ec1, ec2) => (ec1.name > ec2.name ? 1 : (ec2.name > ec1.name ? -1 : 0)))
                        .map((eventClass) => (<EventClassItem key={eventClass.id}
                                                              eventClass={eventClass}
                                                              selected={selected?.id === eventClass.id}
                                                              onClick={() => toggleSelection(eventClass.id)}/>))}
                </TileAncestor>
            </PageContainer>
        </>)
}


