import React, {useContext} from "react";
import {UserContext} from "../api/userContext";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import AuthenticatedNavbar from "../components/AuthenticatedNavbar";
import AdatekezelesiTajekoztato from "./signup/AdatekezelesiTajekoztato";
import RegistrationPage from "./usr/RegistrationPage";
import UserProfile from "./usr/UserProfile";
import {HomePage} from "./usr/Home";
import SignupPage from "./signup";
import Login, {ResetPasswordPage} from "./login";
import Locations from "./adm/location"
import Classes from "./adm/class"
import Events from "./adm/event"
import {NewUserProfileForm} from "./signup/SignupForm";
import {InProgressMsg} from "../components/Progress";
import {RegistrationsReportPage} from "./adm/reports";

export const USER_ROOT = "/";
export const HOME = "/home";
export const PROFILE = "/profile";
export const REGISTER = "/jelentkezes-orara";
export const LOGIN = "/login";
export const SIGNUP = "/signup";

export const RESTORE_ACCOUNT = "/restore-account";
export const DATA_POLICY = "/adatkezelesi-tajekoztato";

export const ADM_LOCATIONS = "/adm/helyszinek";
export const ADM_EVENT_CLASSES = "/adm/csoportok";
export const ADM_EVENTS = "/adm/orarend";
export const ADM_REGISTRATIONS = "/adm/jelentkezesek";



export default function () {

    const { user, authStatus, status } = useContext(UserContext);

    // console.debug('auth status: ', authStatus, status);

    if (authStatus===0)
        return (<InProgressMsg/>);

    if (authStatus > 0 && ! user)
        return PublicRouter();

    if (authStatus > 0 && user && status === 0)
        return SignupRoutes();

    return UserRoutes();
}


const PublicRouter = () => (
    <Router>
        <Switch>
            <Route exact path={LOGIN} component={Login} />
            <Route exact path={ `${LOGIN}/:email` } component={Login} />
            <Route exact path={SIGNUP} component={SignupPage} />
            <Route exact path={RESTORE_ACCOUNT} component={ResetPasswordPage} />
            <Route exact path={ `${RESTORE_ACCOUNT}/:email` } component={ResetPasswordPage} />
            <Route exact path={DATA_POLICY} component={AdatekezelesiTajekoztato} />
            <Route render={()=>(<Redirect to={LOGIN}/>)} />
        </Switch>
    </Router>
)

const SecureRoute = (props) => {
    const { user } = useContext(UserContext);
    return user ? (<Route {...props} />) : (<Redirect to={{ pathname: LOGIN, state: { from: props.location } }} />);
}
const AdmOnlyRoute = (props) => {
    const { user, isAdmin } = useContext(UserContext);
    if (!user)
        return (<Redirect to={{ pathname: LOGIN, state: { from: props.location } }} />)

    if (!isAdmin )
        return (<Redirect to={HOME} />)

    return  (<Route {...props} />);
}

const SignupRoutes = () => (
    <Router>
        <Switch>
            <Route exact path={DATA_POLICY} component={AdatekezelesiTajekoztato} />
            <SecureRoute component={NewUserProfileForm}/>
        </Switch>
    </Router>
)

const UserRoutes = () => (
    <Router>
        <AuthenticatedNavbar/>
        <Switch>
            <Route exact path={DATA_POLICY} component={AdatekezelesiTajekoztato} />

            <SecureRoute exact path={REGISTER} component={RegistrationPage}/>
            <SecureRoute exact path={PROFILE} component={UserProfile} />
            <SecureRoute exact path={HOME} component={HomePage} />

            <AdmOnlyRoute exact path={ADM_LOCATIONS} component={Locations} />
            <AdmOnlyRoute exact path={ADM_EVENT_CLASSES} component={Classes} />
            <AdmOnlyRoute exact path={ADM_EVENTS} component={Events} />
            <AdmOnlyRoute exact path={ADM_REGISTRATIONS} component={RegistrationsReportPage} />

            <Route path={USER_ROOT} render={()=>(<Redirect to={HOME}/>)} />
        </Switch>
    </Router>
);
