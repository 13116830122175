import firebase, {registrationCollection} from "../firebase";
import {regSnapshotToObj} from "./mapping";
import {currentUser} from "./auth";


export const addUserRegistrations = async (event) => {
    const user = currentUser();
    const data = {
        event: event.ref,
        uid: user.uid,
        createdOn: firebase.firestore.Timestamp.fromDate(new Date())
    };
    return await registrationCollection.add(data);
}

export const deleteUserRegistrations = async (id) => {
    return id ? await registrationCollection.doc(id).delete() : null;
}

export const getAllUserRegistrations = async () => {
    const docs = await registrationCollection.get();
    const items = [];
    docs.forEach((snapshot)=>{
        items.push(regSnapshotToObj(snapshot))
    })
    return Promise.all(items);
}

export const listenAllUserRegistrationChanges = (setRegistrations) => {
    const load = async (docs) => {
        const p = []
        docs.forEach((snapshot) => {
            p.push(regSnapshotToObj(snapshot))
        });
        const result = await Promise.all(p);
        setRegistrations(result);
    }
    return registrationCollection.onSnapshot(load)
}

export const getMyUserRegistrations = async () => {
    const user = currentUser();
    if (user && user.uid) {
        const docs = await registrationCollection.where("uid", "==", user.uid).get();
        const items = [];
        docs.forEach((snapshot)=>{
            items.push(regSnapshotToObj(snapshot))
        })
        return Promise.all(items);
    } else {
        return [];
    }

}

export const listenMyUserRegistrationChanges = (setRegistrations) => {
    const user = currentUser();
    if (user && user.uid) {
        const load = async (docs) => {
            const p = []
            docs.forEach((snapshot) => {
                p.push(regSnapshotToObj(snapshot))
            });
            const result = await Promise.all(p);
            setRegistrations(result.filter(r=>!!r.event));
        }
        return registrationCollection.where("uid", "==", user.uid).onSnapshot(load)
    } else {
        return null;
    }
}