import React from "react";
import {TileArticle} from "../../../components/Tiles";

export  const EventClassItem = ({eventClass, selected, onClick}) => {
    return (
        <TileArticle onClick={onClick} selected={selected}>
            <div className="columns">
                <div className="column">
                    <p className="is-size-4 mb-0">{eventClass.name}</p>
                    <p className="has-text-weight-bold is-size-6 mb-2">{eventClass.location?.name || '--Nincs helyszín--'}{eventClass.slots ? `, ${eventClass.slots} hely` : ""}</p>
                    <p className="is-size-6">{eventClass.description}</p>
                </div>
            </div>
        </TileArticle>
    )
}