import React, {useState} from "react";

export const Input = ({name, value, onChange}) => {
    const [stateValue, setStateValue] = useState(value || "");
    const onValueChanged = (evt) => {
        setStateValue(evt.target.value);
        onChange(evt.target.value);
    }
    return (
        <div className="control">
            <input className="input"
                   type="text"
                   placeholder={name}
                   value={stateValue}
                   onChange={onValueChanged}/>
        </div>)
}

export const NumInput = ({name, value, onChange}) => {
    const [stateValue, setStateValue] = useState(value || "");
    const onValueChanged = (evt) => {
        if (evt.target.value === "" || !Number.isNaN(parseInt(evt.target.value))) {
            setStateValue(evt.target.value);
            onChange(parseInt(evt.target.value));
        }
    }
    return (
        <div className="control">
            <input className="input"
                   type="text"
                   placeholder={name}
                   value={stateValue}
                   onChange={onValueChanged}/>
        </div>)
}

export const InputLg = ({name, value, onChange}) => {
    return (
        <div className="control">
            <input className="input is-large" type="text" placeholder={name} value={value}
                   onChange={(evt) => onChange(evt.target.value)}/>
        </div>)
}

export const TextArea = ({name, value, rows=2, onChange}) => {
    return (
        <div className="control">
            <textarea className="textarea" rows={rows} placeholder={name} onChange={(evt) => onChange(evt.target.value)} value={value}/>
        </div>)
}

export const DropDown = ({name, idToValueArrs, selectedId, onChange}) => {
    const [isOpen, setOpen] = useState(false);
    const [selected, setSelected] = useState(selectedId);
    const toggleOpen = ()=>setOpen(!isOpen);

    const select = (newId) => {
        setSelected(newId);
        setOpen(false);
        onChange(newId);
    }

    return (
        <div className={`dropdown ${isOpen?'is-active':''}` }>
            <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleOpen}>
                    <span>{ selected && idToValueArrs.find(el=>el[0]===selected) ? idToValueArrs.find(el=>el[0]===selected)[1] : name}</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {
                        idToValueArrs.map(val=>(
                            <button className="dropdown-item" key={val[0]} onClick={()=>select(val[0])}>
                                {val[1]}
                            </button>))
                    }
                </div>
            </div>
        </div>
    )
}