import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
    apiKey: process.env.REACT_APP_APIKEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_DB,
    projectId: process.env.REACT_APP_PID,
    storageBucket: process.env.REACT_APP_SB,
    messagingSenderId: process.env.REACT_APP_SID,
    appId: process.env.REACT_APP_APPID,
    measurementId:process.env.REACT_APP_MID
};

if (!firebase.apps.length) {
    firebase.initializeApp(config);
    // firebase.analytics();
}

const db = firebase.firestore();

export const userCollection = db.collection( "users" )
export const locationCollection = db.collection( "locations" )
export const eventClassCollection = db.collection( "eventClasses" )
export const eventCollection = db.collection( "events" )
export const recurringEventCollection = db.collection( "recurringEvents" )
export const registrationCollection = db.collection( "registrations" )

export const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

export const auth = firebase.auth();
auth.useDeviceLanguage();

export default firebase;
