import React, {useContext, useEffect, useMemo, useState} from 'react';
import {Link} from 'react-router-dom'
import {UserContext} from "../../api/userContext";
import {Column, Columns} from "../../components/Layout";
import {deleteUserRegistrations, listenMyUserRegistrationChanges} from "../../api/registration";
import ScheduledEvent from "./ScheduledEvent";
import {openCancelRegistrationForm} from "./Modals";
import dayjs from "dayjs";

export const HomePage = () => {
    const { name } = useContext(UserContext);
    const [userRegistrations, setUserRegistrations] = useState(null);
    const [isSaving, setSaving] = useState(false);

    useEffect(()=>{
        return listenMyUserRegistrationChanges(setUserRegistrations);
    }, []);

    const topUpcomingRegistrations = useMemo(()=>{
        const hourAgo = dayjs().subtract(1,'hour');

        if (userRegistrations===null)
            return null;
        else
            return userRegistrations
                .filter(reg=>!!reg.event && reg.event.date && reg.event.date.isAfter(hourAgo))
                .sort((reg1,reg2)=>reg1.event.date.diff(reg2.event.date) > 0 ? 1: (reg1.event.date.diff(reg2.event.date) < 0 ? -1 : 0 ))
                .slice(0,3);
    },[userRegistrations])

    const onCancelEventClicked = (eventId) => {
        if (isSaving)
            return;

        const registration = userRegistrations.find(ur => ur.event && ur.event.id===eventId);

        if (registration)
            openCancelRegistrationForm(registration, ()=>{
                setSaving(true);
                return deleteUserRegistrations(registration.id).then(res=>{
                    setTimeout(()=>setSaving(false), 800);
                })
            })
    }

    return (
        <>
            <section className="section">
                <div className="container">
                    <Columns>
                        <Column width={8} offset={2}>
                            <h3 className="title is-3">Szia {name}!</h3>
                            { topUpcomingRegistrations?.length > 0 ? (<p className="font-alt mb-4">Szeretettel várunk az alábbi órákon. Ha mégsem tudsz jönni, itt le is tudod mondani!</p>) : null }
                            { topUpcomingRegistrations?.length === 0 ? (<p className="font-alt mb-4">Az alábbi gombra kattintva tudsz jelentkezni órára. Sok sikert!</p>) : null }
                            {
                                topUpcomingRegistrations
                                    ? topUpcomingRegistrations.map(reg=>(<ScheduledEvent key={reg.id}
                                                                                event={reg.event}
                                                                                isSelected={false}
                                                                                isDisabled={false}
                                                                                isRegistered={true}
                                                                                onCancelClicked={onCancelEventClicked}/>))
                                    : null
                            }
                            {topUpcomingRegistrations!==null
                                ? (<Link to="/jelentkezes-orara">
                                    <button className="button is-large is-primary is-rounded is-fullwidth">Jelentkezés órára
                                    </button>
                                </Link>) : null
                            }
                        </Column>
                    </Columns>
                </div>
            </section>
        </>
    );
}

