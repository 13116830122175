import {confirmAlert} from "react-confirm-alert";
import React, {useEffect, useState} from "react";
import {Column, Columns} from "../../../components/Layout";
import {Field} from "../../../components/FormComponents";
import {Input} from "../../../components/TextEdit";
import {Buttons, CancelButton, SaveButton} from "../../../components/Buttons";

export const openNewLocationForm = (onSaveAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <LocationForm onSave={onSaveAsync}
                          onClose={onClose}/>)
    });
}

export const openEditLocationForm = (location, onSaveAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <LocationForm name={location.name}
                          email={location.email}
                          address={location.address}
                          phone={location.phone}
                          onSave={onSaveAsync}
                          onClose={onClose}/>)
    });
}

const LocationForm = ({name, address, phone, email, onSave, onClose}) => {

    const [locationName, setLocationName] = useState(name || '');
    const [locationAddress, setLocationAddress] = useState(address || '');
    const [locationPhone, setLocationPhone] = useState(phone || '');
    const [locationEmail, setLocationEmail] = useState(email || '');

    const [isSaveDisabled, setSaveDisabled] = useState(true);

    const resetForm = () => {
        setLocationName("");
        setLocationAddress("");
        setLocationPhone("");
        setLocationEmail("");
    }

    const close = () => {
        resetForm();
        onClose();
    }

    const save = () => {
        setSaveDisabled(true);
        const data = {
            name: locationName,
            address: locationAddress,
            phone: locationPhone,
            email: locationEmail
        }
        resetForm();
        if (typeof onSave === 'function')
            onSave(data);
        onClose();
    }

    useEffect(() => {
        if (locationName)
            setSaveDisabled(false);
        else
            setSaveDisabled(true);

    }, [locationName])

    return (
        <>
            <h1 className="is-size-4 mb-4">Helyszín szerkesztése...</h1>
            <Columns>
                <Column>
                    <Field>
                        <Input name="Név" value={locationName} onChange={setLocationName}/>
                    </Field>
                    <Field>
                        <Input name="Cím" value={locationAddress} onChange={setLocationAddress}/>
                    </Field>
                    <Field>
                        <Input name="Telefon" value={locationPhone} onChange={setLocationPhone}/>
                    </Field>
                    <Field>
                        <Input name="Email" value={locationEmail} onChange={setLocationEmail}/>
                    </Field>
                </Column>
            </Columns>
            <Buttons className={"mt-5"}>
                <SaveButton disabled={isSaveDisabled} onClick={save}/>
                <CancelButton onClick={close}/>
            </Buttons>
        </>
    )

}
