import React from "react";


export default () => {
    return (
        <div className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-half-desktop is-offset-3-desktop is-full-touch">
                        <div className="content">
                            <h4 className="title is-4">Adatkezelési tájékoztató</h4>
                            <p className="">
                                <a href="/adatvedelmi-nyilatkozat-2018.pdf" target="_blank">
                                    Az adatkezelési tájékoztatónk aktuális verzióját ide kattintva érheted el.
                                </a>
                            </p>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )


            }