import {auth, facebookAuthProvider, googleAuthProvider} from "../firebase";

export function onAuthStateChange(handler) {
    return auth.onAuthStateChanged(handler);
}

export function currentUser() {
    const cu = auth.currentUser;
    // console.log('current user', cu);
    return cu;
}

export async function signOut() {
    return await auth.signOut();
}

export function sendPasswordResetEmail(email) {
    // console.debug('sendPasswordResetEmail', email, process.env.REACT_APP_PUBLIC_URL);
    const publicUrl = process.env.REACT_APP_PUBLIC_URL
    const loginUrl = publicUrl.lastIndexOf('/')===publicUrl.length-1? publicUrl + 'login/' +email : publicUrl + '/login/' + email;
    // console.log(loginUrl);
    return auth.sendPasswordResetEmail(email, { url: loginUrl });
}

export async function loginUser(email, pwd) {

    return await auth.signInWithEmailAndPassword(email, pwd);
}

export async function googleLogin() {
    return await auth.signInWithRedirect(googleAuthProvider);
}

export async function facebookLogin() {
    return await auth.signInWithRedirect(facebookAuthProvider);
}

export async function createUser(name, email, pwd) {
    console.debug('createUser', name, email);
    try {
        const userCredential = await auth.createUserWithEmailAndPassword(email, pwd);
        console.log('user created', userCredential);
        const user = auth.currentUser;

        console.log('auth user', user);

        if (user) {
            await user.updateProfile({displayName: name});
            await user.sendEmailVerification({ url: "https://app.betterbell.hu/login" });
            console.log('email sent, displayname updated', user);
        }

        return user;
    }catch (err) {
        console.error(err);
    }
}

export async function verifyUserCode(username, code) {
    console.debug('verifyUserCode', username);
    // return await Auth.confirmSignUp(username, code);
}

export async function resendVerificationCode(username) {
    console.debug('resendVerificationCode', username);
    // return await Auth.resendSignUp(username);
}

export async function sendForgotPasswordCode(email) {
    console.debug('sendVerificationCode', email);
    // return await Auth.forgotPassword(email);
}

export async function resetUserPassword(email, code, pwd) {
    console.debug('resetUserPassword', email, code);
    // return await Auth.forgotPasswordSubmit(email, code, pwd);
}

