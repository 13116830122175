import {Event} from "../model/Event";
import {toDateTime} from "../tools/date";
import {EventClass} from "../model/EventClass";
import {Location} from "../model/Location";
import {Registration} from "../model/Registration";
import {userCollection} from "../firebase";
import {RecurringEvent} from "../model/RecurringEvent";

export const eventSnapshotToObj = async (snapshot) =>{
    const data = snapshot.data();

    let eventClass = null;

    if (data.eventClass){
        const evtClassSnapshot = await data.eventClass.get();
        eventClass = await eventClassSnapshotToObj(evtClassSnapshot);
    }

    return new Event(
        snapshot.id,
        snapshot.ref,
        eventClass,
        data.recurringEvent,
        data.description,
        toDateTime(data.date.toDate()),
        data.numRegs);

}

export const recurringEventSnapshotToObj = async (snapshot) =>{
    const data = snapshot.data();

    if (!data)
        return null;

    let eventClass = null;

    if (data.eventClass){
        const evtClassSnapshot = await data.eventClass.get();
        eventClass = await eventClassSnapshotToObj(evtClassSnapshot);
    }

    return new RecurringEvent(
        snapshot.id,
        snapshot.ref,
        eventClass,
        toDateTime(data.startDate.toDate()),
        data.description);
}

export const eventClassSnapshotToObj = async (snapshot) =>{
    const data = snapshot.data();

    if (!data)
        return null;

    let location = null;

    if (data.location){
        const snapshot = await data.location.get();
        location = mapLocationSnapshotToObj(snapshot);
    }

    return new EventClass(  snapshot.id,
        snapshot.ref,
        data.name,
        data.description,
        location,
        data.slots);
}

export const mapLocationSnapshotToObj = (snapshot) => {
    if (!snapshot)
        return null;

    const data = snapshot.data();

    if (!data)
        return null;

    return new Location(snapshot.id,
        snapshot.ref,
        data.name,
        data.address,
        data.phone,
        data.email);
}

export const regSnapshotToObj = async (snapshot) =>{

    const data = snapshot.data();
    let event = null;
    let user = null;

    if (data.event){
        const evtSnapshot = await data.event.get();
        if (evtSnapshot.exists)
            event = await eventSnapshotToObj(evtSnapshot);
    }

    if (data.user){
        user = await data.user.get();
    } else if (data.uid) {
        user = await userCollection.doc(data.uid).get();
    }

    return new Registration(
        snapshot.id,
        snapshot.ref,
        event,
        user,
        toDateTime(data.createdOn?.toDate()));

}