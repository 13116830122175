

export class EventClass {

    constructor(id, ref, name, description, location, slots) {
        this.id=id;
        this.ref=ref;
        this.name=name;
        this.location=location;
        this.description=description;
        this.slots = slots;
    }

}