import React from "react";
import classnames from 'classnames';

export const Columns = ({children, isMobile}) => (
    <div className={classnames("columns", {"is-mobile": isMobile})}>
        {children}
    </div> );

export const Column = ({width, offset, children}) => (
    <div className={`column ${typeof width=='number'?`is-${width}`:``} ${typeof offset=='number'?`is-offset-${offset}`:``}`}>
        {children}
    </div>);

export const WideBox = ({title, hasLogo, className, children}) => (
    <Centered className={className}>
        <div className="column is-6-widescreen is-full-mobile is-8-tablet">
            <div className="box">
                {title ? (
                    <h4 className="title is-4 has-text-centered">
                        {hasLogo
                            ? (<img src="/betterbell-logo-only-mini.png" alt="Betterbell Logo"
                                    style={{height: '1.1em', paddingRight: '3px', marginBottom: '-3px'}}/>)
                            : null
                        }
                        &nbsp; {title}
                    </h4>
                    ) : null
                }
                {children}
            </div>
        </div>
    </Centered>
)

export const NarrowBox = ({title, hasLogo, className, children}) => (
    <Centered className={className}>
        <div className="column is-4-widescreen is-full-mobile is-6-tablet">
            <div className="box">
                {title ? (
                    <h4 className="title is-4 has-text-centered">
                        {hasLogo
                            ? (<img src="/betterbell-logo-only-mini.png" alt="Betterbell Logo"
                                    style={{height: '1.0em', paddingRight: '5px', marginBottom: '-3px'}}/>)
                            : null
                        }
                        {title}
                    </h4>
                ) : null
                }
                {children}
            </div>
        </div>
    </Centered>
)


export const Centered = ({className, children})=>(<>
    <section className={classnames("hero","is-fullheight", className)}>
        <div className="hero-body">
            <div className="container">
                <div className="columns is-centered">
                    { children }
                </div>
            </div>
        </div>
    </section>
    </>)
