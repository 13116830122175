import React, {useContext, useState} from "react";
import {Link, useHistory} from "react-router-dom";
import {createUser} from "../../api/auth";
import {validateEmail, validatePassword} from "../../tools/validation";
import {Checkbox, ErrorMessage, FormField} from "../../components/FormComponents";
import {faEnvelope, faKey, faUserNinja} from "@fortawesome/free-solid-svg-icons";
import {Column, Columns, WideBox} from "../../components/Layout";
import * as userApi from '../../api/user';
import {UserContext} from "../../api/userContext";
import {DATA_POLICY} from "../UserRoutes";

export const SignupForm = ({onSignedUp}) => {
    const genericErrorMsg = 'Valami hiba történt. Várj egy kicsit és próbáld meg újra!';

    const history = useHistory();

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [pwd, setPwd] = useState();
    const [pwd2, setPwd2] = useState();

    const [errors, setErrors] = useState({
        nameError: null,
        emailError: null,
        pwdError: null,
        pwd2Error: null
    });
    const [showErrors, setShowErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [displayMsg, setDisplayMsg] = useState();
    const [errorMsg, setErrorMsg] = useState();


    async function signupUser(name, email, pwd) {
        const createUserResult = await createUser(name, email, pwd);
        console.debug('createUserResult', createUserResult);
        if ( createUserResult) {
            onSignedUp(email);
            return true;
        }
        return false;
    }

    const onNext = async (evt)=>{

        evt.preventDefault();

        const err = {
            nameError: null,
            emailError: null,
            pwdError: null,
            pwd2Error: null
        }

        if(!email)
            err.emailError = "Kérlek, adj meg egy email címet!";
        else if (!validateEmail(email))
            err.emailError = "Úgy tűnik, ez nem egy valid email cím!";

        if(!name)
            err.nameError = "Kérlek, add meg a neved!";
        else if (name.length<3)
            err.nameError = "Túl rövid név, legalább 3 karaktert adj meg!";

        if(!pwd)
            err.pwdError = "Kérlek, add meg egy jelszót!";
        else if (!validatePassword(pwd))
            err.pwdError = "Min. 6 karakter, legyen benne egy nagybetű, egy kisbetű, egy szám!";
        else {
            if(!pwd2)
                err.pwd2Error = "Kérlek, add meg a jelszót mégegyszer!";
            else if (pwd2!==pwd)
                err.pwd2Error = "Úgy tűnik, a két jelszó nem egyezik!";
        }

        if ( !err.nameError && !err.emailError && !err.pwdError && !err.pwd2Error ) {
            setShowErrors(false);
            setIsLoading(true);
            try {
                if ( await signupUser(name, email, pwd) )
                    return ;
            } catch (e) {
                console.error('SignupForm.onSubmit error', e);
                setIsLoading(false);
                if (e && e.code === 'UsernameExistsException') {
                    err.emailError = 'Ez az email cím már regisztrálva van nálunk.'
                } else {
                    setErrorMsg(genericErrorMsg);
                }
            }
        }
        setIsLoading(false);
        setErrors(err);
        if (err.nameError || err.emailError || err.pwdError || err.pwd2Error ) {
            setShowErrors(true);
        }
    }

    const onCancelClicked = () => {
        history.push(`/login/`);
    }

    return (
        <WideBox title="Regisztráció" hasLogo={true} >
            <Columns>
                <Column width={8} offset={2}>
                    { errorMsg?( <ErrorMessage>{errorMsg}</ErrorMessage>):null }
                    <form>
                        <fieldset disabled={isLoading}>

                            <FormField name="Email" type="email" icon={faEnvelope} placeholder="peldabela@gmail.com"
                                       onChange={setEmail} error={ showErrors && errors.emailError } isValid={email && !errors.emailError && validateEmail(email)} />
                            <FormField name="Név" type="name" icon={faUserNinja} placeholder="Név vagy becenév, min. 3 karakter"
                                       onChange={setName} error={ showErrors  && errors.nameError }
                                       isValid={name && !errors.nameError && name.length >=3 }/>
                            <FormField name="Jelszó" icon={faKey} type="password" placeholder="******"
                                       onChange={setPwd} message="Min. 6 karakter, legyen benne nagybetű, kisbetű és szám"
                                       error={ showErrors && errors.pwdError }
                                       isValid={pwd && !errors.pwdError && validatePassword(pwd) }/>
                            <FormField name="Jelszó mégegyszer" icon={faKey} type="password" placeholder="******" onChange={setPwd2}
                                       error={ showErrors && errors.pwd2Error }
                                       isValid={ pwd && !errors.pwd2Error && validatePassword(pwd) && pwd2 && pwd2===pwd }/>

                            <div className="field ">
                                <div className="buttons is-centered">
                                    <button className={`button is-rounded is-outlined ${isLoading?'is-loading':''}`} onClick={onNext}>
                                        Tovább
                                    </button>
                                    <button className="button is-rounded is-dark" onClick={onCancelClicked}>
                                        Mégse
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </Column>
            </Columns>
        </WideBox>
    );
}


const createUserProfile = async ( user, name ) => {
    if (user && user.email && name) {
        console.log('new profile: ', user.uid, name, user.email);
        return await userApi.createUserProfile(user.uid, name, user.email);
    }
    return null;
}

export const NewUserProfileForm = ({onSignedUp}) => {
    const genericErrorMsg = 'Valami hiba történt. Várj egy kicsit és próbáld meg újra!';

    const { user, reloadUserProfile } = useContext(UserContext);

    const [name, setName] = useState();
    const [acceptDataPolicy, setAcceptDataPolicy] = useState(false);

    const [errors, setErrors] = useState({
        nameError: null, acceptDataPolicyError: null
    });
    const [showErrors, setShowErrors] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState();

    const onSave = async (evt) => {
        evt.preventDefault();

        const err = {
            nameError: null,
            acceptDataPolicyError: null
        }

        if(!name)
            err.nameError = "Kérlek, add meg a neved!";
        else if (name.length<3)
            err.nameError = "Túl rövid név, legalább 3 karaktert adj meg!";

        if(!acceptDataPolicy)
            err.acceptDataPolicyError = "Kérlek, fogadd el az adatkezelési tájékoztatót!";

        if ( !err.nameError && !err.acceptDataPolicyError ) {
            setShowErrors(false);
            setIsLoading(true);
            try {
                await createUserProfile( user, name );
                reloadUserProfile();
            } catch (e) {
                console.error('NewUserProfileForm.onSubmit error', e);
                setIsLoading(false);
                setErrorMsg(genericErrorMsg);
            }
        }
        setIsLoading(false);
        setErrors(err);
        if (err.nameError || err.acceptDataPolicyError ) {
            setShowErrors(true);
        }
    }

    return (
        <WideBox title="Üdv!" hasLogo={true} >
            <Columns>
                <Column width={8} offset={2}>
                    { errorMsg?( <ErrorMessage>{errorMsg}</ErrorMessage>):null }
                    <p className={"has-text-centered"}>Ha jól látom, először vagy itt...</p>
                    <p className={"has-text-centered"}>Kérlek, add meg a neved és fogadd el az
                        adatkezelési feltételeket a továbblépéshez!</p>
                    <hr/>
                    { errorMsg?( <ErrorMessage>{errorMsg}</ErrorMessage>):null }
                    <form>
                        <fieldset disabled={isLoading}>
                            <FormField name="Név" type="name" icon={faUserNinja} placeholder="Név vagy becenév, min. 3 karakter"
                                       onChange={setName} error={ showErrors  && errors.nameError }
                                       isValid={name && !errors.nameError && name.length >=3 }/>

                            <Checkbox onChanged={setAcceptDataPolicy} error={showErrors && errors.acceptDataPolicyError}>
                                <span>Elolvastam és elfogadom az <Link to={DATA_POLICY}>adatkezelési feltételeket</Link> </span>
                            </Checkbox>
                            <div className="field ">
                                <div className="buttons is-centered">
                                    <button className={`button is-rounded is-outlined ${isLoading?'is-loading':''}`} onClick={onSave}>
                                        Tovább
                                    </button>
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </Column>
            </Columns>
        </WideBox>
    );
}