import React, {useEffect, useState} from "react";
import * as admin from "../../../api/admin";
import {Event} from "../../../model/Event";

import {PageContainer, PageActions} from "../../../components/PageContainer";
import {sameDay} from "../../../tools/date";
import {Calendar} from "./Calendar";
import {openDeleteEventsForm, openNewEventForm} from "./EventModals";
import {Buttons, DeleteButton, NewButton} from "../../../components/Buttons";
import {RecurringEvent} from "../../../model/RecurringEvent";

const createEvent = async (eventDate, eventClass, description) => {
    const event = new Event(null, null, eventClass, null, description, eventDate, 0)
    return admin.createEvent(event);
}

const createRecurringEvent = async (startDate, eventClass, recurringDays, description) => {
    const event = new RecurringEvent(null, null, eventClass, startDate, recurringDays, description)
    return admin.addRecurringEvent(event);
}

const deleteEvents = (events) => {
    return Promise.all(events.map(evt=>evt.id).map(id=>admin.deleteEvent(id)));
}

const deleteRecurringEvents = (events) => {
    return Promise.all(events.map(evt=>{
        if (evt.recurringEventRef && evt.recurringEventRef.id)
            return admin.deleteRecurringEvent(evt.recurringEventRef.id);
        else
            return admin.deleteEvent(evt.id);
    }));
}

export const EventPage = () => {

    const [loading, setLoading] = useState(true);

    const [events, setEvents] = useState([]);
    const [eventClasses, setEventClasses] = useState([]);

    const [selectedDays, setSelectedDays] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);

    useEffect(()=>admin.listenEventChanges(setEvents),[]);
    useEffect(()=>admin.listenEventClassChanges(setEventClasses),[]);

    const onNewClicked = () => {
        openNewEventForm(eventClasses, (eventData) => {

            let eventTimeHourMin = eventData.eventTime ? eventData.eventTime.split(":").map(v=>parseInt(v)) : null;
            let recurringDays = eventData.recurringDays ? parseInt(eventData.recurringDays) : 0;

            const eventClass = eventClasses.find(evtCls=>evtCls.id===eventData.eventClassId);

            if (eventTimeHourMin.length === 2 && eventClass) {
                const promiseArr = selectedDays.map((day) => {
                    const date = day.hour(eventTimeHourMin[0]).minute(eventTimeHourMin[1]).second(0).millisecond(0);
                    if (recurringDays <= 0)
                        return createEvent(date, eventClass, eventData.description);
                    else
                        return createRecurringEvent(date, eventClass, recurringDays, eventData.description);
                })
                return Promise.all(promiseArr);
            }
        });
    }

    const onDeleteSelectedEventsClicked = () => {
        onDeleteEvents(selectedEvents);
    }

    const onDeleteEvents = (events) => {
        if (events.length > 0 ) {
            openDeleteEventsForm(events, async (doDeleteRecurringEvents)=>{
                return doDeleteRecurringEvents
                    ? deleteRecurringEvents(events)
                    : deleteEvents(events);
            })
        }
    }

    useEffect(()=>{
        const finishedLoading = ( events && events.length > 0) || (eventClasses && eventClasses.length > 0 )
        return setLoading( ! finishedLoading );
    }, [events, eventClasses]);


    useEffect(()=>{
        if (selectedDays.length===0)
            setSelectedEvents([]);

        const selectedEvents = events
            .filter(evt=>selectedDays.some(d=>sameDay(d, evt.date)))
            .sort((e1,e2)=>e1.date.toDate()-e2.date.toDate());
        setSelectedEvents(selectedEvents);
    }, [selectedDays, events])

    return (
        <>
            <PageContainer>
                <PageActions title="Órarend" >
                    <Buttons hasAddons={true} hidden={loading}>
                        <DeleteButton disabled={selectedEvents.length === 0} onClick={onDeleteSelectedEventsClicked}/>
                        <NewButton disabled={selectedDays.length === 0} onClick={onNewClicked}/>
                    </Buttons>
                </PageActions>
                <Calendar events={events} onSelectionChange={setSelectedDays} onDeleteEvent={(evt)=>onDeleteEvents([evt])}/>
            </PageContainer>
        </>)
}