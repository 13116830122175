import React, {useEffect, useState} from "react";
import {confirmAlert} from "react-confirm-alert";
import {Column, Columns} from "../../../components/Layout";
import {Checkbox, Field} from "../../../components/FormComponents";
import {Input} from "../../../components/TextEdit";
import {Buttons, CancelButton, SaveButton} from "../../../components/Buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";

export const openCopyEventsForm = (events, onCloneAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <CopyEventsForm    events={events}
                                onSave={ onCloneAsync }
                                onClose={onClose}/>)
    });
}

export const openNewEventForm = (eventClasses, onSaveAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <NewEventForm   eventClasses={eventClasses}
                            onSave={ onSaveAsync }
                            onClose={onClose}/>)
    });
}

export const openDeleteEventsForm = (events, onDeleteAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <DeleteEventsForm  events={events}
                               onConfirm={ onDeleteAsync }
                               onClose={onClose}/>)
    });
}


const DeleteEventsForm = ({events, onConfirm, onClose}) => {
    const [deleteRecurring, setDeleteRecurring] = useState(false);

    const resetForm = () => {
        setDeleteRecurring(false);
    }

    const close = () => {
        resetForm();
        onClose();
    }

    const confirm = async () => {
        await onConfirm(deleteRecurring);
        close();
    }

    return (
        <>
            <h1 className="is-size-4 mb-4">Biztos törlöd ezeket az órákat?</h1>
            <Columns>
                <Column>
                    <Field>
                    {
                        events
                            .map(event=>{
                                const msg = event.date.format("HH:mm") + " " + (event.eventClass?event.eventClass.name:"--") + " (" +event.date.format("MMM.DD")+ ")";
                                // const recurring = !!event.recurringEventRef;
                                return (<p key={event.id}>{msg} {event.recurringEventRef ? (<FontAwesomeIcon icon={faHistory}/>): null}</p>)
                            })
                    }
                    </Field>
                </Column>
            </Columns>
            {
                events.some(evt=>!!evt.recurringEventRef) ?
                    (<Checkbox onChanged={setDeleteRecurring}>
                        {'Töröld az ismétlődéseket is!'}
                    </Checkbox>) : null
            }
            <div className="buttons mt-5">
                <button className="button is-danger" onClick={confirm}>Törlés</button>
                <button className="button" onClick={close}>Mégse</button>
            </div>
        </>
    )
}

const CopyEventsForm = ({events, onSave, onClose}) => {
    const [days, setDays] = useState(0);
    const [isSaveDisabled, setSaveDisabled] = useState(true);

    const resetForm = () => {
        setDays(0);
    }

    const close = () => {
        resetForm();
        onClose();
    }

    const save = () => {
        setSaveDisabled(true);
        const daysToCopy = days;
        resetForm();
        onSave(daysToCopy);
        onClose();
    }

    useEffect(()=>{
        if (events && events.length > 0 && days > 0)
            setSaveDisabled(false);
        else
            setSaveDisabled(true);

    }, [events, days])

    const eventDesc = events.map(event=>event.date.format("HH:mm") + " " + event.eventClass.name + " (" +event.date.format("MMM.DD")+ ")");

    return (
        <>
            <h1 className="is-size-4 mb-4">Másolás...</h1>
            {
                eventDesc.map((evtDesc,idx)=>(<p key={idx}>{evtDesc}</p>))
            }
            <div className="level is-mobile">
                <div className="level-left">
                    <div className="level-item">
                        <p>Napok hozzáadása:</p>
                    </div>
                    <div className="level-item">
                        <div className="select">
                            <select onChange={(e)=>setDays(parseInt(e.target.value))} value={`${days}`}>
                                <option value="0">--</option>
                                { Array(7).fill()
                                    .map((_,idx)=>idx+1)
                                    .map((val,idx)=>(<option key={idx} value={`${val}`}>{val}</option>))}
                                <option value={`14`}>14</option>
                                <option value={`21`}>21</option>
                                <option value={`28`}>28</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttons mt-5">
                <button className="button is-success" disabled={isSaveDisabled} onClick={save}>Másolás</button>
                <button className="button" onClick={close}>Mégse</button>
            </div>

        </>)

}

const NewEventForm = ({eventClasses, onSave, onClose}) => {

    const [showSaveError, setShowSaveError] = useState(null);
    const [startHour, setStartHour] = useState("");
    const [startMin, setStartMin] = useState("");
    const [recurringDays, setRecurringDays] = useState("");
    const [selectedEventClassId, setSelectedEventClassId] = useState("");
    const [description, setDescription] = useState("");
    const [isSaveDisabled, setSaveDisabled] = useState(true);

    const resetForm = () => {
        setStartHour("");
        setStartMin("");
        setSelectedEventClassId("");
        setDescription("");
    }

    const close = () => {
        resetForm();
        onClose();
    }

    const save = async () => {
        setSaveDisabled(true);
        const eventTime = startHour+":"+startMin;
        const eventClassId = selectedEventClassId;
        const data = { eventTime, eventClassId, recurringDays, description }

        try {
            await onSave(data);
            resetForm();
            onClose();
        } catch (err) {
            console.error(err);
            setShowSaveError(true);
        }


    }

    useEffect(()=>{
        if (startHour && startMin && selectedEventClassId  && recurringDays)
            setSaveDisabled(false);
        else
            setSaveDisabled(true);

    }, [startHour, startMin, selectedEventClassId, recurringDays])

    return (
        <>
            <h1 className="is-size-4 mb-4">Új óra szerkesztése...</h1>
            <Columns>
                <Column>
                    { showSaveError ? (<Field>
                        <div className="notification is-danger">
                            Ajajj, valami hiba történt!
                        </div>
                    </Field>) : null}
                    <Field>
                        <div className="select">
                            <select onChange={(e)=>setSelectedEventClassId(e.target.value)} value={selectedEventClassId}>
                                <option value="">Csoport...</option>
                                { eventClasses.map((evtCls, idx)=>(<option key={idx} value={evtCls.id}>{evtCls.name} ({evtCls.location.name})</option>))}
                            </select>
                        </div>
                    </Field>
                    <Field>
                        <div className="level is-mobile">
                            <div className="level-left">
                                <div className="level-item">
                                    <p className={"pl-3"}>Kezdés:</p>
                                </div>
                                <div className="level-item">
                                    <div className="select">
                                        <select onChange={(e)=>setStartHour(e.target.value)} value={startHour}>
                                            <option value="">--</option>
                                            { Array(18).fill()
                                                .map((_,idx)=>idx+5)
                                                .map((val)=>val<10?"0"+val:""+val)
                                                .map((val,idx)=>(<option key={idx} value={val}>{val}</option>))}
                                        </select>
                                    </div>
                                </div>
                                <div className="level-item">
                                    <div className="select">
                                        <select onChange={(e)=>setStartMin(e.target.value)} value={startMin}>
                                            <option value="">--</option>
                                            { Array(12).fill()
                                                .map((_,idx)=>idx*5)
                                                .map(val=>val<10?"0"+val:""+val)
                                                .map((val,idx)=>(<option key={idx} value={val}>{val}</option>))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Field>
                    <Field>
                        <div className="select">
                            <select onChange={(e)=>setRecurringDays(e.target.value)} value={recurringDays}>
                                <option value="">Ismétlődés...</option>
                                <option value="0">Csak egyszer</option>
                                <option value="7">Hetente</option>
                            </select>
                        </div>
                    </Field>
                    <Field>
                        <Input name="Egyéb infó" value={description} onChange={setDescription} />
                    </Field>
                </Column>
            </Columns>
            <Buttons className={"mt-5"}>
                <SaveButton disabled={isSaveDisabled} onClick={save}/>
                <CancelButton onClick={close}/>
            </Buttons>
        </>
    )
}
