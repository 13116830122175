import React, {useEffect, useMemo, useState} from "react";
import classnames from "classnames";
import {displayDay, monday, sameDay, weeksUntil} from "../../../tools/date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import '../../../styles/Calendar.sass';


const DailyEvent = ({event, onDeleteClicked}) => (
    <div className="event">
        <div>
            <p className={"is-size-6"}>
                {event.date?.format("HH:mm")} {event.eventClass?event.eventClass.name:"--"} {event.recurringEventRef ? (<FontAwesomeIcon icon={faHistory}/>): null}</p>
            {event.description?(<p className="is-size-7">{event.description}</p>):null}
        </div>
        <button className={classnames("delete", "is-small")} aria-label="close"
                onClick={(e)=>{e.stopPropagation();onDeleteClicked();}}/>
    </div>);

const DateOfWeek = ({ name, date, onClick}) => (
    <div className={ classnames('tile', 'is-parent', 'is-2', 'p-1') } onClick={()=>(onClick?onClick():null)}>
        <div className="tile is-child box notification  is-info">
            {/*{name?(<p className={"subtitle has-text-centered"}>{name}</p>):null}*/}
            {date?(<p className={"subtitle has-text-centered"}>{date.format("MMMM")}</p>):null}
            {date?(<p className={"title has-text-centered"}>{date.format("D.")}</p>):null}
        </div>
    </div>);

const WeekDay = ({children, title, selected, onClick }) => (
    <div className={ classnames('tile','is-parent', 'is-2', 'px-1') } onClick={()=>(onClick?onClick():null)} >
        <div className={ classnames('tile', 'is-child', 'box', 'px-2', 'py-2', 'min-height-100', { 'selected': selected} ) } >
            {title?(<p className={"is-size-7"}>{title}</p>):null}
            {children}
        </div>
    </div>);


const WeekContainer = ({children}) => (<>{children}</>)

export const Calendar = ({events, onSelectionChange, onDeleteEvent}) => {

    const [selected, setSelected] = useState([]);

    const weeks = useMemo(()=>{
        return weeksUntil(monday(),56).map( week=>week.filter(day => day.day() > 0 && day.day() < 6))
    }, [])

    const toggleSelection = (days) => {
        if (days && days.length ) {
            setSelected((selected)=>{
                const newItems = days.filter( d =>!selected.some(dd=>sameDay(dd,d)) );
                const oldItems = selected.filter( d =>!days.some( dd=>sameDay(d,dd)) ); // keep not removed items
                const result = oldItems.concat(newItems);
                return result;
            } );
        }
    }

    const toggleWeek = (week) => {
        console.log('toggleWeek', week);
        if (week && week.length > 0) {
            setSelected((selected)=>{
                const allDaysSelected = week.filter( d => selected.some( dd=>sameDay(dd,d)) ).length === week.length;
                if (allDaysSelected) {
                    return selected.filter(d => !week.some(dd=>sameDay(dd,d)) ); // unselect week's days
                } else {
                    const newItems = week.filter( d => !selected.some(dd=>sameDay(dd,d)) );
                    return selected.concat(newItems);
                }
            } );
        }
    }

    const deleteEvent = (evt) => {
        onDeleteEvent(evt);
    }

    useEffect(()=>{
        if (typeof onSelectionChange === 'function' )
            onSelectionChange(selected);
    }, [selected, onSelectionChange] )

    return (
        <div className={`tile is-ancestor wrap calendar`}>
            {weeks
                ? weeks.map((week, idx) => (
                    <WeekContainer key={idx}>
                        <DateOfWeek name={week[0].format("MMM D.")} date={week[0]} onClick={() => toggleWeek(week)} />
                        {
                            week.map((day, idx) => (
                                    <WeekDay key={idx}
                                             title={displayDay(day, "dddd")}
                                             selected={selected.some(d=>sameDay(d,day))}
                                             onClick={() => toggleSelection([day])}>

                                        {
                                            events && events
                                                .filter(evt => sameDay(evt.date, day))
                                                .sort((e1,e2)=>e1.date.toDate()-e2.date.toDate())
                                                .map((evt, idxx) => (
                                                    <DailyEvent key={idxx}
                                                                event={evt}
                                                                onDeleteClicked={()=>deleteEvent(evt)}/>))
                                        }
                                    </WeekDay>
                                ))}
                    </WeekContainer>
                ))
                : null}
        </div>
    );

}


export const CalendarTitle = ({ children, title }) => {

    return (
        <nav className="level min-height-40">
            <div className="level-left">
                <div className="level-item">
                    <p className="title">
                        <strong>{title}</strong>
                    </p>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                    {children}
                </div>
            </div>
        </nav>
    )
}