import React from "react";

export const PageContainer = ({children, title})=> (
    <div className="page-container">
        <section className="section ">
            <div className="container">
                {title?(<h3 className="title is-3">{title}</h3>):null}
                {children}
            </div>
        </section>
    </div>)


export const PageActions = ({ children, title }) => {

    return (
        <nav className="level min-height-40">
            <div className="level-left">
                <div className="level-item">
                    <p className="title">
                        <strong>{title}</strong>
                    </p>
                </div>
            </div>
            <div className="level-right">
                <div className="level-item">
                    {children}
                </div>
            </div>
        </nav>
    )
}