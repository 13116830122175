import React, {useEffect, useState} from "react";

import '../styles/DateTimeIcon.sass';
import dayjs from "dayjs";
// import moment from "moment";

const months=["jan.", "feb.", "márc.", "ápr.", "máj.", "jún.", "júl.", "aug.", "szept.", "okt.", "nov.", "dec." ];
const days=["vas.", "hét.", "kedd", "sze.", "csüt.", "pén.", "szo."];

function _parse(time) {
    const dt = time?new Date(time.toString()):null;

    if (!dt)
        return null;

    return {
        origTime: time,
        month: months[dt.getMonth()],
        day: days[dt.getDay()],
        dayOfMonth: dt.getDate(),
        time: dayjs(dt).format("HH:mm")
    }
}

export default ({time}) => {
    const [dateTime, setDateTime] = useState(null);
    useEffect(()=>{
        setDateTime(_parse(time));
    },[time])
    return dateTime?(
        <time dateTime={dateTime.origTime} className="icon">
            <em>{dateTime.time}</em>
            <strong>{dateTime.month} {dateTime.dayOfMonth}</strong>
            <span>{dateTime.day}</span>
        </time>
    ):(<></>);
}
