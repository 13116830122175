import {currentUser} from "./auth";
import {userCollection} from "../firebase";
import dayjs from "dayjs";

export async function createUserProfile(uid, nickname, email) {

    const userProfile = {
        uid: uid,
        name: nickname,
        email: email,
        status: 10,
        isAdmin: false,
        termsAndConditionsAccepted: true,
        termsAndConditionsAcceptedOn: dayjs().format()
    };

    return  await userCollection.doc(uid).set(userProfile);
}


export async function retrieveUserProfile() {
    const user = currentUser();
    if (! user || ! user.uid) {
        console.warn('Current user not found');
        return null;
    }
    try {

        const doc = await userCollection.doc(user.uid).get();
        if (doc.exists)
            return doc.data();
        else {
            return {
                uid: user.uid,
                email: user.email,
                name: user.displayName,
                emailVerified: user.emailVerified,
                isAdmin: false,
                status: 0
            }
        }
    }catch (err){
        console.error(err);
        return null;
    }
}