import React from "react";
import {confirmAlert} from "react-confirm-alert";


export const confirmDelete = (title, messages, onDeleteAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <AlertDeleteItem title={title}
                             messages={messages}
                             onOk={() => {
                                 const promise = onDeleteAsync();
                                 if (promise)
                                     promise.then(onClose)
                                 else
                                     onClose();
                             }}
                             onCancel={onClose}/>)
    });
}

const AlertDeleteItem = ({title, messages, onOk, onCancel}) => {
    return (
        <div>
            <h1 className="is-size-3">{title}</h1>
            { messages ? messages.map((message,idx)=>(<p key={idx}>{message}</p>)) : null }
            <p>&nbsp;</p>
            <div className="buttons">
                <button onClick={onOk}  className="button is-danger">
                    Igen, törölhető!
                </button>
                <button onClick={onCancel} className="button is-dark">Mégsem</button>
            </div>
        </div>
    );
}