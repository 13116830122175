import React from "react";
import classnames from "classnames";


export const TileAncestor = ({children}) => (<div className="tile is-ancestor wrap">{children}</div>)

export const TileArticle = ({children, onClick, className, selected}) => (
    <div className={`tile is-parent is-3 ${className?className:''}`} onClick={()=>(onClick?onClick():null)}>
        <article className={classnames("tile","is-child","box","min-height-200", {selected:selected} )}>
            {children}
        </article>
    </div>)
