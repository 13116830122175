import {Centered} from "./Layout";
import React from "react";

export const InProgressMsg = ({msg}) => (
    <Centered className={"is-fullpage"}>
        <div className="column is-6-widescreen is-full-mobile is-8-tablet">
            <p className="has-text-centered font-alt is-size-1">
                { msg || "Tartsad..."}
            </p>
        </div>
    </Centered>);