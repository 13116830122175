import firebase, {
    locationCollection,
    eventCollection,
    eventClassCollection,
    recurringEventCollection
} from "../firebase";
import {eventClassSnapshotToObj, eventSnapshotToObj, mapLocationSnapshotToObj} from "./mapping";


/***************************************************
 *
 *                   LOCATIONS
 *
 ***************************************************/


export const saveLocationData = async (id, locationData) => {
    if (id) {
        const docRef = locationCollection.doc(id);
        if ( (await docRef.get()).exists ) {
            await docRef.set({
                name:locationData.name,
                address:locationData.address || '',
                phone: locationData.phone || '',
                email: locationData.email || ''
            });
        } else {
            throw new Error('Location does not exist');
        }
    } else {
        throw new Error('Location ID is missing');
    }
}

export const addLocationData = async (locationData) => {
    return await locationCollection.add({
        name:locationData.name,
        address:locationData.address || '',
        phone: locationData.phone || '',
        email: locationData.email || ''
    });
}

export const deleteLocationData = async (id) => {
    return await locationCollection.doc(id).delete();
}

export const listLocations = async () => {
    const docs = await locationCollection.get();
    const items = [];
    docs.forEach((snapshot)=>{
        items.push(mapLocationSnapshotToObj(snapshot));
    })
    return items;
}

export const listenLocationChanges = (setLocations) => {
    locationCollection.onSnapshot( (docs)=>{
        const items = [];
        docs.forEach((snapshot)=>{
            items.push(mapLocationSnapshotToObj(snapshot));
        })
        setLocations(items);
    })
}

/***************************************************
 *
 *                   EVENT CLASSES
 *
 ***************************************************/

export const saveEventClassData = async (id, eventClass) => {
    if (!id)
        throw new Error('Document ID is missing');

    const docRef = eventClassCollection.doc(id);

    if ( ! (await docRef.get()).exists )
        throw new Error('Document does not exist');

    await docRef.set({
        name: eventClass.name,
        description: eventClass.description,
        location: eventClass.location.ref,
        slots: eventClass.slots
    });
}

export const addEventClassData = async (eventClass) => {
    console.log('addEventClassData', eventClass);
    return await eventClassCollection.add({
        name: eventClass.name,
        description: eventClass.description,
        location: eventClass.location.ref,
        slots: eventClass.slots
    });
}

// export const useListEventClasses = (props) => {
//     const [eventClasses, setEventClasses] = useState([]);
//     useEffect(() => {
//         console.log('useListEventClasses.useEffect');
//         return () => {
//             return listenEventClassChanges(setEventClasses);
//         };
//     },[]);
//     return eventClasses;
// }

export const listEventClasses = async () => {
    const docs = await eventClassCollection.get();
    const items = [];
    docs.forEach((snapshot)=>{
        items.push(eventClassSnapshotToObj(snapshot))
    })
    return Promise.all(items);
}

export const listenEventClassChanges = (setEventClasses) => {

    const load = async (docs)=>{
        const p = []
        docs.forEach((snapshot)=>{
            p.push(eventClassSnapshotToObj(snapshot))
        });
        const result = await Promise.all(p);
        // setEventClasses([]);
        setEventClasses(result);
    }

    return eventClassCollection.onSnapshot(load)
}

export const deleteEventClassData = async (id) => {
    return await eventClassCollection.doc(id).delete();
}

/***************************************************
 *
 *                   EVENTS
 *
 ***************************************************/

export const createEvent = async (event) => {
    console.log('saving new event', event);
    return await eventCollection.add({
        date: firebase.firestore.Timestamp.fromDate(event.date.toDate()),
        eventClass: event.eventClass.ref,
        description: event.description || null});
}

export const deleteEvent = async (id) => {
    return await eventCollection.doc(id).delete();
}

export const listEvents = async () => {
    const docs = await eventCollection.get();
    const items = [];
    docs.forEach((snapshot)=>{
        items.push(eventSnapshotToObj(snapshot))
    })
    return Promise.all(items);
}

export const listenEventChanges = (setEvents) => {
    const load = async (docs)=>{
        const p = []
        docs.forEach((snapshot)=>{
            p.push(eventSnapshotToObj(snapshot))
        });
        const result = await Promise.all(p);
        setEvents(result);
    }
    return eventCollection.onSnapshot(load)
}


/***************************************************
 *
 *                   RECURRING EVENTS
 *
 ***************************************************/

export const addRecurringEvent = async (recurringEvent) => {
    if (recurringEvent.startDate && recurringEvent.eventClass && recurringEvent.recurringDays && recurringEvent.recurringDays > 0) {
        console.log('saving new recurring event', recurringEvent);
        return await recurringEventCollection.add({
            startDate: firebase.firestore.Timestamp.fromDate(recurringEvent.startDate.toDate()),
            recurringDays: recurringEvent.recurringDays,
            eventClass: recurringEvent.eventClass.ref,
            description: recurringEvent.description || null
        });
    } else {
        throw new Error("Missing data, cannot create recurring event");
    }
}

export const deleteRecurringEvent = async (id) => {
    if (id)
        return await recurringEventCollection.doc(id).delete();
    else
        return null;
}