export class RecurringEvent {

    constructor(id, ref, eventClass, startDate, recurringDays, description) {
        this.id=id;
        this.ref=ref;
        this.eventClass=eventClass;
        this.startDate = startDate;
        this.recurringDays = recurringDays;
        this.description=description;
    }

}