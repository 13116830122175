import React, {useEffect, useMemo, useState} from "react";
import {listEvents} from "../../../api/admin";
import {
    getAllUserRegistrations,
    listenAllUserRegistrationChanges,
} from "../../../api/registration";
import {Column, Columns} from "../../../components/Layout";
import {InProgressMsg} from "../../../components/Progress";
import dayjs from "dayjs";
import classnames from "classnames";
import {sameDay, sameWeek} from "../../../tools/date";
import {capitalize} from "../../../tools/string";

export const RegistrationsReport = () => {

    // ********************** State variables **********************
    const [isLoading, setLoading] = useState(true);

    const [registrations, setRegistrations] = useState([]);
    const [events, setEvents] = useState([]);

    // ********************** Initialization **********************
    useEffect(() => {
        const fetchEvents = async () => {
            setEvents(await listEvents());
        }

        const fetchRegistrations = async () => {
            setRegistrations(await getAllUserRegistrations());
        }

        Promise.all([
            fetchEvents(),
            fetchRegistrations()
        ]).then(() => setLoading(false));

    }, [])

    useEffect(()=>{
        return isLoading ? undefined : listenAllUserRegistrationChanges(setRegistrations);
    }, [isLoading]);

    // ********************** Creating render variables **********************
    const eventsRegMap = useMemo( ()=> {
        if (registrations) {
            return registrations.reduce((map, reg) => {
                if (reg && reg.event && reg.event.id) {
                    const key = reg.event.id;
                    if (map.has(key)) {
                        map.get(key).push(reg);
                    } else {
                        map.set(key, [reg]);
                    }
                }
                return map;
            }, new Map());
        }
        else
            return [];
    },[registrations]);

    const displayEvents = useMemo( ()=>{
        if (events) {
            return events
                .filter(evt=>evt.date.isAfter( dayjs().add(-1, 'h') ))
                .sort((e1, e2) => (e1.date > e2.date ? 1 : (e2.date > e1.date ? -1 : 0)))
        } else {
            return [];
        }
    }, [events]);

    return isLoading
        ? (<LoadingScreen/>) :
        (<PageContainer>
            {
                displayEvents.map(event=>(<DisplayEvent key={event.id} event={event} registrations={eventsRegMap.get(event.id) || []}/>))
            }
        </PageContainer>);
}

const DisplayEvent = ({event, registrations}) => {
    // console.log(event);

    const displayDate = useMemo(()=>{
        if (sameDay(event.date, dayjs()) )
            return "Ma";
        if ( sameDay(event.date, dayjs().add(1,'d')) )
            return "Holnap";
        if ( sameWeek(event.date, dayjs()) )
            return capitalize(event.date.format("dddd"));
        else
            return capitalize(event.date.format("MMM. D."));
    }, [event.date]);

    return (<div className={"box"}>
        <div className={"content"}>
            <div className="level mb-0">
                <div className="level-left">
                    <div className="level-item">
                        <h5  className={"title is-5 "}>
                            {displayDate} {event.date.format("HH:mm")}
                        </h5 >
                    </div>
                    <div className="level-item">
                        <h6 className={"title is-6"}>
                            {event.eventClass.name}@{event.eventClass.location.name}
                        </h6>
                    </div>
                </div>
                <div className="level-right">
                    <div className="level-item">
                        {registrations.length>0
                            ?(<span className={classnames(
                                "tag",
                                "ml-3",
                                {"is-success": registrations.length > 3},
                                {"is-warning": registrations.length <= 3})}
                            >{registrations.length} jelentkező</span>)
                            :(<span className="tag is-danger ml-3">
                                Nincs jelentkező
                            </span>)}
                    </div>
                </div>
            </div>

            <hr/>

            {   registrations.length > 0
                ? registrations.map(reg=>{
                            const user = reg.user.data();
                            return (
                                <Columns key={reg.id} isMobile={true}>
                                    <Column width={4}>
                                        {user.name}
                                    </Column>
                                    <Column>
                                        {user.email}
                                    </Column>
                                </Columns>)
                            })
                : <p>Erre az órára még nincs jelentkező</p>
            }
        </div>
        { registrations.map(reg=>(<p key={reg.id}>{reg.uid}</p>)) }
    </div>);
}

const PageContainer = ({children}) => {
    return (
        <div className="registration-page">
            <section className="section ">
                <div className="container">
                    <Columns>
                        <Column width={8} offset={2}>
                            {children}
                        </Column>
                    </Columns>
                </div>
            </section>
        </div>);
}

const LoadingScreen = ()=>(<InProgressMsg msg={"Mindjárt..."}/>)