import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faCopy, faTimes} from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";

export const Buttons = ({children, className, hidden, hasAddons, areSmall})=> {
    return hidden
        ? null
        : ( <div className={classnames("buttons", className, {"has-addons":hasAddons}, {"are-small":areSmall}) }>
                {children}
            </div>)
}


export const DeleteButton = ({onClick, disabled, label})=>(
        <button className="button " disabled={disabled} onClick={onClick}>
            <FontAwesomeIcon icon={faTimes}/>&nbsp;{label || 'Töröl' }
        </button>
    )

export const TrashButton = ({onClick, disabled, label})=>(
    <button className="button" disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faTimes}/>{label?(<>&nbsp;{label}</>):null}
    </button>
)

export const EditButton = ({onClick, disabled, label})=>(
        <button className="button " disabled={disabled} onClick={onClick}>
            <FontAwesomeIcon icon={faEdit}/>&nbsp;{label || 'Szerkeszt'}
        </button>
    )

export const CopyButton = ({onClick, disabled, label})=>(
    <button className="button " disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faCopy}/>&nbsp;{label || 'Másol'}
    </button>
)

export const NewButton = ({onClick, disabled, label})=>(
    <button className="button is-primary " disabled={disabled} onClick={onClick}>
        <FontAwesomeIcon icon={faPlus}/>&nbsp;{label || 'Új'}
    </button>
)

export const SaveButton = ({onClick, disabled, label})=>(
    <button className={classnames("button", "is-success")} disabled={disabled} onClick={onClick}>
        {label || 'Mentés'}
    </button>
)

export const CancelButton = ({onClick, label})=>(
    <button className="button" onClick={onClick}>{label || 'Mégse'}</button>
)

export const DefaultButton = ({onClick, label})=>(
    <button className={classnames("button")} onClick={onClick}>{label}</button>
)

export const DangerButton = ({onClick, disabled, label, icon})=>(
    <Btn classname={"is-danger"} onClick={onClick} disabled={disabled} label={label} icon={icon}/>
)

export const WarningButton = ({className, onClick, disabled, label, icon})=>(
    <Btn className={ ["is-warning", className] } onClick={onClick} disabled={disabled} label={label} icon={icon}/>
)

export const SuccessButton = ({onClick, label})=>(
    <button className={classnames("button", "is-success")} onClick={onClick}>{label}</button>
)

export const PrimaryButton = ({className, onClick, disabled, label, icon})=>(
    <Btn className={ ["is-primary", className] } onClick={onClick} disabled={disabled} label={label} icon={icon}/>
)

const Btn = ({className, onClick, disabled, label, icon})=>(
    <button className={classnames("button", ...className)} onClick={onClick} disabled={disabled}>
        {icon?(<><FontAwesomeIcon icon={icon}/>&nbsp;</>):null}
        {label}
    </button>
)
