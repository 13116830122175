import dayjs from "dayjs";
import 'dayjs/locale/hu'

dayjs.locale('hu');

export const toDateTime = (timestamp) => {
    return dayjs(timestamp);
}

export const sameDay = (date1, date2) => {
    if (date1 && date2) {
        return date1.isSame(date2, 'day');
    }
    return false;
}

export const sameWeek = (date1, date2) => {
    if (date1 && date2) {
        const dayOfWeek1 = date1.day();
        return date1.add(6-dayOfWeek1, 'd').isAfter(date2) && date1.subtract(dayOfWeek1, 'd').isBefore(date2);
    }
    return false;
}

export const monday = () => {
    return dayjs().day() > 0 ? dayjs().day(1) : dayjs().set('day', -6);
}

export const daysUntil = (from, length) => {
    if (from && length) {
        return Array.from({length: length}, (x, i) => from.add(i, 'day'));
    }
    return [];
}

export const weeksUntil = (from, length) => {
    const days = daysUntil(from, length);

    return days.reduce((all,one,i) => {
        const ch = Math.floor(i/7);
        all[ch] = [].concat((all[ch]||[]),one);
        return all
    }, [])
}

export const displayDay= (day, format) => {
    return day && format ? day.format(format): "";
}