import React, {useContext, useState} from "react";
import {UserContext} from "../api/userContext";
import classnames from 'classnames';
import {signOut} from "../api/auth";
import {Link} from "react-router-dom";
import {PrimaryButton} from "./FormComponents";
import {ADM_EVENT_CLASSES, ADM_EVENTS, ADM_LOCATIONS, ADM_REGISTRATIONS, HOME, REGISTER} from "../ui/UserRoutes";
import OutsideClickHandler from "react-outside-click-handler/esm/OutsideClickHandler";

export default () => {

    const { user, isAdmin } = useContext(UserContext);

    // console.log('user: ', user, isAdmin);

    const [isOpen, setOpen] = useState(false);
    const [isAdmActive, setAdmActive] = useState(false);

    function toggleOpen(e) {
        e.stopPropagation();
        setOpen(isOpen=>!isOpen);
    }

    function closeDropdowns() {
        setAdmActive(false);
        setOpen(false);
    }

    function toggleAdmActive(e) {
        e.stopPropagation();
        setAdmActive(isAdmActive=>{
            setOpen(false);
            return !isAdmActive;
        });
    }

    function onLogout() {
        signOut().then(()=>{});
    }

    return user ? (
        <OutsideClickHandler onOutsideClick={() => {
            setAdmActive(false);
            closeDropdowns()
        }}>
            <nav className="navbar" role="navigation" aria-label="main navigation" onClick={closeDropdowns}>
                <div className="navbar-brand">
                    <a className="navbar-item" href="/">
                        <img src="/betterbell-logo-small.png" alt="logo"/>
                    </a>

                    <button onClick={toggleOpen}
                       className={`navbar-burger burger ${isOpen ? 'is-active' : ''}`} aria-label="menu"
                       aria-expanded="false"
                       data-target="navbarBasicExample">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </button>
                </div>

                <div className={classnames("navbar-menu", {"is-active":isOpen})}>
                    <div className="navbar-start">
                        <Link to={HOME} className="navbar-item" onClick={closeDropdowns}>
                            Főoldal
                        </Link>

                        <Link to={REGISTER} className="navbar-item" onClick={closeDropdowns}>
                            Jelentkezés órára
                        </Link>


                        { isAdmin ? (
                            <>
                                <Link to={ADM_REGISTRATIONS} className="navbar-item" onClick={closeDropdowns}>
                                    Jelentkezések
                                </Link>
                                <div className={`navbar-item has-dropdown ${isAdmActive ? 'is-active' : ''}`}>
                                    <button className="navbar-link" onClick={toggleAdmActive}>
                                        Admin
                                    </button>
                                    <div className="navbar-dropdown">
                                        <Link to={ADM_EVENTS} className="navbar-item" onClick={toggleAdmActive}>
                                            Órarend
                                        </Link>
                                        <hr className="navbar-divider"/>
                                        <Link to={ADM_EVENT_CLASSES} className="navbar-item" onClick={toggleAdmActive}>
                                            Csoportok
                                        </Link>
                                        <Link to={ADM_LOCATIONS} className="navbar-item" onClick={toggleAdmActive}>
                                            Helyszínek
                                        </Link>
                                    </div>
                                </div>
                            </>
                        ) : null }

                    </div>

                    <div className="navbar-end">
                        <div className="navbar-item">
                            {user.email}
                        </div>
                        <div className="navbar-item">
                            <PrimaryButton onClick={onLogout}>Kilép</PrimaryButton>
                        </div>
                    </div>
                </div>
            </nav>
        </OutsideClickHandler>

    ) : null;

}