import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory} from 'react-router-dom'

import {WideBox} from "../../components/Layout";
import {CenteredButtonsField, FormField, PrimaryButton, SecondaryButton} from "../../components/FormComponents";
import {validateEmail, validatePassword} from "../../tools/validation";

import {faEnvelope, faKey, faTimesCircle, faUserPlus} from "@fortawesome/free-solid-svg-icons";

import '../../styles/Login.sass';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {facebookLogin, googleLogin, loginUser} from "../../api/auth";

export const LoginPage = ({email:emailParam}) => {
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [pwd, setPwd] = useState();
    const [errors, setErrors] = useState({
        emailError: null,
        pwdError: null,
    });
    const [showErrors, setShowErrors] = useState(false);
    const [displayMsg, setDisplayMsg] = useState();
    const [isEmailLoginLoading, setIsEmailLoginLoading] = useState(false);


    const emailInputRef = useRef();
    const pwdInputRef = useRef();


    useEffect(() => {
        if (emailInputRef && emailInputRef.current) {
            emailInputRef.current.value = emailParam || '';
            setEmail(emailParam);
            if (emailParam && pwdInputRef && pwdInputRef.current) {
                pwdInputRef.current.focus();
            }
        }

    }, [emailParam])

    const onSignInClicked = async (evt) => {
        evt.preventDefault();

        setDisplayMsg(null);

        const err = {
            emailError: null,
            pwdError: null,
        }

        if (!email)
            err.emailError = "Add meg a bejelentkezési email címed!";
        else if (!validateEmail(email))
            err.emailError = "Úgy tűnik, ez nem egy valid email cím!";


        if (!pwd)
            err.pwdError = "Kérlek, itt add meg a jelszavad!";
        else if (!validatePassword(pwd))
            err.pwdError = "Ez nem egy valid jelszó. Biztos ezt adtad meg a regisztrációnál?";


        if (err.emailError || err.pwdError) {
            console.log('errors', err);
            setErrors(err);
            setShowErrors(true);

        } else {

            setShowErrors(false);
            setIsEmailLoginLoading(true);

            try {
                setShowErrors(false);
                const result = await loginUser(email, pwd);
                console.log('login result', result);
            } catch (e) {
                console.error(e.code || e);
                setIsEmailLoginLoading(false);
                if (e && e.code === 'auth/user-not-found') {
                    err.emailError = 'Ez az email nincs regisztrálva.'
                } else if (e && e.code === 'auth/wrong-password') {
                    err.pwdError = 'Hibás jelszó.';
                } else {
                    setDisplayMsg({
                        cls: 'is-danger is-light',
                        txt: 'Valami hiba történt, kérlek, próbáld később!'
                    });
                }

                if (err.emailError || err.pwdError) {
                    setPwd('');
                    setErrors(err);
                    setShowErrors(true);
                }
            }
        }
    }

    const onRegBtnClicked = () => {
        history.push("/signup")
    }



    return (
        <WideBox className="login-page" title="Itt tudsz belépni" hasLogo={true}>
            <div className="level">
                <div className="level-item social-login has-text-centered">
                    <div className="buttons">
                        <GoogleLoginBtn/>
                        <FacebookLoginBtn/>
                    </div>
                </div>
                <div className="level-item email-login">
                    <div>
                        {
                            displayMsg ?
                                (<div className={`notification ${displayMsg.cls}`}>
                                    <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        className="delete"
                                        onClick={() => setDisplayMsg(null)}/>
                                    {displayMsg.txt}
                                </div>) : null
                        }
                        <form style={{width: '100%'}}>
                            <fieldset disabled={isEmailLoginLoading}>
                                <FormField name="Email" type="email" icon={faEnvelope}
                                           inputRef={emailInputRef}
                                           placeholder="peldabela@gmail.com"
                                           autocomplete="email"
                                           onChange={setEmail}
                                           isValid={email && !errors.emailError && validateEmail(email)}
                                           error={showErrors && errors.emailError}/>
                                <FormField name="Jelszó" icon={faKey} type="password" placeholder="******"
                                           inputRef={pwdInputRef}
                                           autocomplete="current-password"
                                           onChange={setPwd}
                                           error={showErrors && errors.pwdError}
                                           isValid={pwd && !errors.pwdError && validatePassword(pwd)}/>
                                <CenteredButtonsField>
                                    <PrimaryButton isLoading={isEmailLoginLoading} onClick={onSignInClicked}>
                                        Belépek
                                    </PrimaryButton>
                                    <SecondaryButton onClick={onRegBtnClicked}>
                                        <small><FontAwesomeIcon icon={faUserPlus}/></small> &nbsp; Regisztrálok
                                    </SecondaryButton>
                                </CenteredButtonsField>
                                <p className="has-text-centered">
                                    {!isEmailLoginLoading ? (
                                        <Link to={`/restore-account/${email || ''}`}><small>Problémám van a
                                            bejelentkezéssel</small></Link>
                                    ) : null
                                    }
                                </p>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </WideBox>)
}





const GoogleLoginBtn = () => (
    <button className="button is-outlined is-rounded is-danger is-fullwidth"
            onClick={() => googleLogin()}>
        Google/Gmail
    </button>);

const FacebookLoginBtn = () => (
    <button className="button is-outlined is-rounded is-info is-fullwidth"
            onClick={() => facebookLogin()}>
        Facebook
    </button>);