import React, {useEffect, useState} from "react";

import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import '../../../styles/Admin.sass'

import * as admin from "../../../api/admin";
import {PageActions, PageContainer} from "../../../components/PageContainer";
import {confirmDelete} from "../../../components/Alert";
import {Buttons, DeleteButton, EditButton, NewButton} from "../../../components/Buttons";
import {openEditLocationForm, openNewLocationForm} from "./LocationModals";
import {TileArticle} from "../../../components/Tiles";


/*************************
 * Data validation and API calls
 *************************/
const handleCreateLocation = () => {
    return async (locationData) => {
        if (locationData.name) {
            await admin.addLocationData(locationData);
        } else {
            throw new Error("Error while creating location: name is mandatory");
        }
    }
}

const handleSaveLocation = (locationId) => {
    return async (locationData) => {
        if (locationData.name) {
            await admin.saveLocationData(locationId, locationData);
        } else {
            throw new Error("Error while saving location: name is mandatory");
        }
    }
}

const handleDeleteLocation = (location) => {
    const messages = [location.name];
    confirmDelete("Biztos törlöd?", messages, async () => {
        return admin.deleteLocationData(location.id);
    });
}

export const LocationsPage = () => {

    const [loading, setLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [selected, setSelected] = useState(null);

    useEffect(()=>{
        const fetchLocations = async () => {
            setLocations(await admin.listLocations());
        }
        fetchLocations().then(()=>setLoading(false));
    },[])

    useEffect(()=>{
        return loading ? undefined : admin.listenLocationChanges(setLocations);
    }, [loading]);


    /*************************
     * Event Handlers
     *************************/
    const onClickNew = () => {
        return openNewLocationForm(handleCreateLocation());
    }

    const onClickEdit = () => {
        if (selected && selected.id)
            openEditLocationForm(selected, handleSaveLocation(selected.id));
    }

    const onClickDelete = () => {
        if (selected && selected.id)
            handleDeleteLocation(selected);
    }

    const toggleSelection = (locationId) => {
        if (selected && selected.id === locationId)
            setSelected(null)
        else
            setSelected(locations.find(loc => loc.id === locationId))
    }

    return (
        <PageContainer>
            <PageActions title="Helyszínek">
                <Buttons areSmall={false} hasAddons={true} hidden={loading}>
                    <DeleteButton disabled={selected == null} onClick={onClickDelete}/>
                    <EditButton disabled={selected == null} onClick={onClickEdit}/>
                    <NewButton onClick={onClickNew}/>
                </Buttons>
            </PageActions>
            <div className="tile is-ancestor wrap">
                { locations.map((location) =>
                    <Location key={location.id}
                              location={location}
                              selected={selected?.id === location.id}
                              onClick={() => toggleSelection(location.id)}/>
                )}
            </div>
        </PageContainer>)
}

const Location = ({location, selected, onClick }) => {
    return (
        <TileArticle onClick={onClick} selected={selected}>
            <div className="columns">
                <div className="column">
                    <p className="is-size-4 mb-2">{location.name}</p>
                    <p className="is-size-6">{location.address}</p>
                    <p className="is-size-6">{location.phone}</p>
                    <p className="is-size-6">{location.email}</p>
                </div>
            </div>
        </TileArticle>
    );
}
