import React from "react";
import {LoginPage} from "./Login";
import {ResetPassword} from "./RestoreAccount";

export default function ({match}) {
    return (<LoginPage email={match?.params?.email}/>);
}


export const ResetPasswordPage = ({match}) => {
    return (<ResetPassword email={match?.params?.email}/>);
};
