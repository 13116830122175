import React, {useState} from "react";
import {Link} from "react-router-dom";

import {Column, Columns, WideBox} from "../../components/Layout";
import {CenteredButtonsField, InfoMessage, PrimaryButton,} from "../../components/FormComponents";

import '../../styles/Login.sass';
import '../../styles/Form.sass';

import {SignupForm} from "./SignupForm";

export default () => {
    const [registered, setRegistered] = useState(null);

    async function onSignedUp(email) {
        setRegistered(email);
    }

    if (registered)
        return (<SuccessfulRegistration email={registered}/>);
    else
        return (<SignupForm onSignedUp={onSignedUp}/>);;
}
//
// const SuccessfulVerification = ({email}) => {
//     return (
//         <WideBox title="Regisztráció" hasLogo={true}>
//             <Columns>
//                 <Column width={10} offset={1}>
//                     <InfoMessage>
//                         <FontAwesomeIcon icon={faCheck}/> &nbsp; A regisztráció sikeres volt. Köszönjük!
//                     </InfoMessage>
//                     <CenteredButtonsField>
//                         <PrimaryButton>
//                             <Link to={`/login?email=${email}`}>
//                                 Tovább a bejelentkezéshez
//                             </Link>
//                         </PrimaryButton>
//                     </CenteredButtonsField>
//                 </Column>
//             </Columns>
//         </WideBox>
//     );
// }

const SuccessfulRegistration = ({email}) => {
    return (
        <WideBox title="Sikeres regisztráció" hasLogo={true}>
            <Columns>
                <Column width={10} offset={1}>
                    <InfoMessage>
                        Küldtünk egy megerősítő email. A folytatáshoz kérlek, nyisd meg és kattints a benne lévő linkre!
                    </InfoMessage>
                    <CenteredButtonsField>
                        <PrimaryButton>
                            <Link to={`/login?email=${email}`}>
                                Tovább
                            </Link>
                        </PrimaryButton>
                    </CenteredButtonsField>
                </Column>
            </Columns>
        </WideBox>
    );
}
