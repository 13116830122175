import React, {useContext, useState} from "react";
import { Redirect }  from 'react-router-dom';
import {UserContext} from "../../api/userContext";
import {createUserProfile} from "../../api/user";




const Form = ({onSaveAndAccept})=>{
    const [nickname, setNickname] = useState(null);
    const [acceptGdpr, setAcceptGdpr] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);

    function isValidNickname() {
        return nickname && nickname.length >= 3;
    }

    function save() {
        if (!isValidNickname() || !acceptGdpr) {
            setInvalidForm(true);
        } else {
            if (typeof onSaveAndAccept === 'function')
                onSaveAndAccept(nickname)
        }
    }

    return (
        <div className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-half-desktop is-full-touch is-offset-3-desktop">
                        <div className="content">
                            <h1 className="title is-2">Hello!</h1>
                            <p >Úgy tűnik, először jársz nálunk...</p>

                            <p >Kezdésnek, kérlek, adj meg egy becenevet, amin szólíthatunk,
                                illetve olvasd el és fogadd el az adatkezelési
                                tájékoztatónkat!</p>
                            <p>
                                <input className={`input ${invalidForm&&!isValidNickname()?'is-danger':''}`}
                                       type="text"
                                       placeholder="Becenév, min. 3 karakter"
                                       onChange={(evt)=>setNickname(evt.target.value)}/>
                            </p>
                            <p>
                                <label className="checkbox">
                                    <input type="checkbox" onClick={(evt)=>setAcceptGdpr(!acceptGdpr)}/>
                                    <span>&nbsp; Az <a href="/adatvedelmi-nyilatkozat-2018.pdf" target="_blank">adatkezelési
                                tájékoztatót</a>
                                        &nbsp;elfogadom</span>
                                </label>
                            </p>
                            <hr/>
                            <div className="buttons">
                                <button className="button is-normal" onClick={save}>Tovább</button>
                            </div>

                            {
                                invalidForm?(
                                    <p className="has-text-danger">Hoppá! Biztos jól adtál meg mindent?
                                        Valami hiányzik, kérlek, nézd át mégegyszer!</p>
                                ) : null
                            }

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default () => {
    const { user, setUser } = useContext(UserContext);

    const [userProfile, setUserProfile] = useState(user && user.userProfile);

    function onSaveAndAccept(nickname) {
        createUserProfile(user.uid, nickname, user.email).then(userProfile=>{
            console.log('user profile created', userProfile);

            user.userProfile = userProfile;
            setUser(user);
            setUserProfile(userProfile);

        })
    }

    if (!user) // this shouldn't happen here...
        return (<p>Hoppá, valami hiba történt. Kérlek frissítsd az oldalt!</p>);

    if (user && userProfile){ // if it has a user profile already, go to Home
        return (<Redirect to="/home"/>);
    }

    return (<Form onSaveAndAccept={onSaveAndAccept}/>);

}