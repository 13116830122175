import {confirmAlert} from "react-confirm-alert";
import React, {useEffect, useState} from "react";
import {Input, NumInput, TextArea} from "../../../components/TextEdit";
import {Buttons, CancelButton, SaveButton} from "../../../components/Buttons";
import {Column, Columns} from "../../../components/Layout";
import {Field} from "../../../components/FormComponents";


export const openNewEventClassForm = (locations, onSaveAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <EventClassForm  name={""} description={""} locationId={""} slots={0} locations={locations}
                                onSave={ onSaveAsync }
                                onClose={onClose}/>)
    });
}

export const openEditEventClassForm = (eventClass, locations, onSaveAsync) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <EventClassForm  name={eventClass.name}
                                description={eventClass.description}
                                locationId={eventClass.location?.id}
                                slots = { eventClass.slots }
                                locations={locations}
                                onSave={ onSaveAsync }
                                onClose={onClose}/>)
    });
}

const EventClassForm = ({name, description, locationId, slots, locations, onSave, onClose}) => {

    const [eventClassName, setEventClassName] = useState(name);
    const [eventClassDescription, setEventClassDescription] = useState(description);
    const [eventClassLocationId, setEventClassLocationId] = useState(locationId || "");
    const [eventClassSlots, setEventClassSlots] = useState(slots);
    const [isSaveDisabled, setSaveDisabled] = useState(true);

    const resetForm = () => {
        setEventClassName("");
        setEventClassDescription("");
        setEventClassLocationId("");
        setEventClassSlots(0);
    }

    const close = () => {
        resetForm();
        onClose();
    }

    const save = () => {
        setSaveDisabled(true);
        const data = {
            name:eventClassName,
            description: eventClassDescription,
            location: locations.find(l=>l.id===eventClassLocationId),
            slots: eventClassSlots
        }
        resetForm();
        onSave(data);
        onClose();
    }

    useEffect(()=>{
        if (eventClassName && eventClassLocationId )
            setSaveDisabled(false);
        else
            setSaveDisabled(true);

    }, [eventClassName, eventClassDescription, eventClassLocationId])

    return (
        <>
            <h1 className="is-size-4 mb-4">Új csoport szerkesztése...</h1>
            <Columns>
                <Column>
                    <Field>
                        <Input name="Csoport neve" value={eventClassName} onChange={setEventClassName} />
                    </Field>
                    <Field>
                        <div className="select">
                            <select onChange={(e)=>setEventClassLocationId(e.target.value)} value={eventClassLocationId}>
                                <option value="">- Helyszín -</option>
                                { locations.map((loc,idx)=>(<option key={idx} value={loc.id}>{loc.name}</option>)) }
                            </select>
                        </div>
                    </Field>
                    <Field>
                        <NumInput name="Szabad helyek" value={eventClassSlots} onChange={setEventClassSlots} />
                    </Field>
                    <Field>
                        <TextArea name="Leírás-megjegyzés" value={eventClassDescription} onChange={setEventClassDescription} />
                    </Field>

                </Column>
            </Columns>
            <Buttons className={"mt-5"}>
                <SaveButton disabled={isSaveDisabled} onClick={save}/>
                <CancelButton onClick={close}/>
            </Buttons>
        </>
    )

}
