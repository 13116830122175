export class Registration {

    constructor(id, ref, event, user, createdOn) {
        this.id=id;
        this.ref=ref;
        this.event=event;
        this.user=user;
        this.uid=user?.uid;
        this.createdOn=createdOn;
    }

}