export class Event {

    constructor(id, ref, eventClass, recurringEventRef, description, date, numRegs) {
        this.id=id;
        this.ref=ref;
        this.eventClass=eventClass;
        this.recurringEventRef=recurringEventRef;
        this.description=description;
        this.date = date;
        this.numRegs = numRegs;
    }

}