import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckSquare, faSquare} from "@fortawesome/free-solid-svg-icons";


export const InfoMessage = ({children}) => (<div className={`notification is-info`}>{children}</div>);
export const ErrorMessage = ({children}) => (<div className={`notification is-danger is-light `}>{children}</div>);

export const PrimaryButton = ({children, isLoading, isDisabled, onClick}) => (
    <button className={`button is-rounded is-outlined ${isLoading?'is-loading':''}`} onClick={onClick} disabled={isDisabled}>
        {children}
    </button>
)

export const SecondaryButton = ({children, isLoading, isDisabled, onClick}) => (
    <button className={`button is-rounded is-dark ${isLoading?'is-loading':''}`} onClick={onClick} disabled={isDisabled}>
        {children}
    </button>
)

export const ButtonsField = ({children}) => (<div className="field"><div className="buttons">{children}</div></div>);

export const CenteredButtonsField = ({children}) => (<div className="field"><div className="buttons is-centered">{children}</div></div>);

export const Field = ({children})=>(<div className={"field"}>{children}</div>)

export const FormField = ({name, type, inputRef, placeholder, autocomplete, icon, required, onChange, message, error, isValid })=>(
    <div className="field">
        <label className="label">{name}</label>
        <div className="control has-icons-left has-icons-right">
            { required
                ? (<input type={type}
                          ref={ref=>inputRef ? inputRef.current=ref : null}
                          placeholder={placeholder}
                          autoComplete={autocomplete}
                          className="input is-primary"
                          onChange={(evt)=>onChange(evt.target.value && evt.target.value.trim())} required/>)
                : (<input type={type}
                          ref={ref=>inputRef ? inputRef.current=ref : null}
                          placeholder={placeholder}
                          autoComplete={autocomplete}
                          className="input is-primary"
                          onChange={(evt)=>onChange(evt.target.value && evt.target.value.trim())}/>)
            }
            <FontAwesomeIcon icon={icon} className="icon is-left is-small"/>
            {isValid? (<FontAwesomeIcon icon={faCheck} className="icon is-right is-small"/>):null}

        </div>
        { (error && !isValid )? (<p className="help is-danger">{error}</p>):(message ? (<p className="help">{message}</p>):(<p className="help">&nbsp;</p>)) }
    </div>);


export const Checkbox = ({children, error, onChanged}) => {
    const [checked,setChecked] = useState(false);
    const change = () => {
        setChecked(checked=>!checked);
    }
    useEffect(()=>{
        onChanged(checked);
    },[checked,onChanged]);
    return (
        <div className="field">
            <FontAwesomeIcon icon={checked?faCheckSquare:faSquare} onClick={change} className={"mr-1"}/>
            {children}
            { (error)? (<p className="help is-danger">{error}</p>):null }
        </div>
    );

}
