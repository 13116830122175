import React from "react";
import classnames from "classnames";

import {Kettlebells} from "../../components/icons";
import DateTimeIcon from "../../components/DateTimeIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";


export default ({event, isDisabled, isSelected, isRegistered, onClick, onCancelClicked})=>{
    const clicked = (evt) => {
        evt.preventDefault();

        if (!isDisabled && typeof onClick==='function')
            onClick(event.id);
    }
    const cancelClicked = (evt) => {
        evt.preventDefault();
        evt.stopPropagation();
        if (!isDisabled && typeof onCancelClicked==='function')
            onCancelClicked(event.id);
    }
    return (
        <div className={classnames("box",{"selected":isSelected})} onClick={clicked}>
            <nav className="level is-mobile">
                <div className="level-left">
                    <div className="level-item">
                        <DateTimeIcon time={event.date} />
                    </div>
                    <div className="level-item">
                        <EventDetails location={event.eventClass.location.name}
                                      name={event.eventClass.name}
                                      remainingSlots={event.eventClass?.slots?event.eventClass.slots-event.numRegs:-1}
                                      showCancelBtn={isRegistered}
                                      onCancelClicked={cancelClicked}/>
                    </div>
                </div>
                <div className="level-right">
                    <SelectIcon selected={isRegistered}/>
                </div>
            </nav>
        </div>
    )
}

const EventDetails = ({name, location, remainingSlots, showCancelBtn, onCancelClicked})=>{
    return (
        <div className="event-details">
            <div className="level mb-0">
                <div className="level-left">
                    <strong>{location}</strong>&nbsp;
                </div>
                <div className="level-left is-size-7">
                    #{name}
                </div>
            </div>
            <p className="is-size-7">{ remainingSlots>=0 ? "Szabad helyek: " + remainingSlots : "Szabad" }</p>
            {showCancelBtn
                ? (<button className={"button is-outlined is-small"} onClick={onCancelClicked}>
                    <FontAwesomeIcon icon={faTimes}/>&nbsp;Lemondom
                </button>)
                : null }

        </div>
    );
}

const SelectIcon = ({selected}) => {

    const iconStyle = {
        transform: selected?'rotate(360deg)':'',
        transition: 'all .5s ease-out'
    };

    const svgStyle = {
        fill:selected?'#FFD700':'rgba(255,255,255,.2)',
        transition: 'all .5s ease-out'
    };

    return (
        <Kettlebells style={iconStyle} svgstyle={svgStyle} width="40px" height="40px"/>
    )
}