import React from "react";
import {Column, Columns} from "../../components/Layout";
import {Field} from "../../components/FormComponents";
import {capitalize} from "../../tools/string";
import {confirmAlert} from "react-confirm-alert";


export const openCancelRegistrationForm = (registration, onConfirm) => {
    return confirmAlert({
        closeOnEscape: true,
        closeOnClickOutside: false,
        customUI: ({onClose}) => (
            <CancelRegistrationForm     registration={registration}
                                        onConfirm={onConfirm}
                                        onClose={onClose}/>)
    });
}

const CancelRegistrationForm = ({registration, onConfirm, onClose}) => {

    const close = () => {
        onClose();
    }

    const confirm = async () => {
        await onConfirm();
        close();
    }

    return (
        <>
            <h1 className="is-size-4 mb-4">Biztos lemondod ezt az órát?</h1>
            <Columns>
                <Column>
                    <Field>
                        <p >{capitalize(registration.event?.date?.format("MMM. D. HH:mm"))} - {registration.event?.eventClass?.name}@{registration.event?.eventClass?.location?.name} </p>
                    </Field>
                </Column>
            </Columns>
            <div className="buttons mt-5">
                <button className="button is-danger" onClick={confirm}>Igen, lemondom</button>
                <button className="button" onClick={close}>Mégse</button>
            </div>
        </>
    )
}