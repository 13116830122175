import React, {useEffect, useRef, useState} from "react";


export default ({title, options, onChange}) => {

    const containerRef = useRef();
    const [caption, setCaption] = useState(title);
    const [selectedId, setSelectedId] = useState(null);
    const [isOpen, setOpen] = useState(false);

    function toggleOpen() {
        setOpen(!isOpen);
    }

    function handleClickOutside(evt) {
        if (containerRef.current && !containerRef.current.contains(evt.target)) {
            if (isOpen)
                setOpen(false);
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    })

    useEffect(() => {
        setCaption(selectedId && options.find(opt => opt.id === selectedId)
            ? options.find(opt => opt.id === selectedId).name
            : title);
    }, [options, selectedId, title])

    function select(id) {
        setSelectedId(id);
        setOpen(false);
        if (typeof onChange == 'function')
            onChange(id);
    }

    return (
        <div className={`dropdown ${isOpen ? 'is-active' : ''}`} ref={containerRef}>
            <div className="dropdown-trigger">
                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu" onClick={toggleOpen}>
                    <span>{caption}</span>
                    <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                </button>
            </div>
            <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                    {
                        options.map((option, idx) => (
                            <button key={idx}
                                    className={`dropdown-item`}
                                    onClick={() => select(option.id)}>
                                {option.name}
                            </button>
                        ))
                    }
                </div>
            </div>
        </div>);
}