import React, {useState, useRef, useEffect} from "react";

import { useHistory } from "react-router-dom";

import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import {Column, Columns, WideBox} from "../../components/Layout";
import {
    CenteredButtonsField,
    FormField,
    InfoMessage,
    PrimaryButton,
    SecondaryButton
} from "../../components/FormComponents";
import {validateEmail} from "../../tools/validation";
import * as auth from "../../api/auth";


export const ResetPassword = ({email: emailParam}) => {

    const inputRef = useRef();

    const history = useHistory();

    const [email, setEmail] = useState();
    const [errors, setErrors] = useState({ emailError: null });
    const [showErrors, setShowErrors] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    useEffect(()=>{
        if (inputRef && inputRef.current) {
            inputRef.current.value = emailParam || '';
            setEmail(emailParam);
        }
    }, [emailParam])

    const backToLogin = () => {
        history.push("/login/" + (emailParam || ''));
    }

    const onResetPasswordClicked = async (evt) => {
        evt.preventDefault();

        setShowErrors(false);

        const err = {
            emailError: null
        }
        if(!email)
            err.emailError = "Kérlek, adj meg az email címed!";
        else if (!validateEmail(email))
            err.emailError = "Úgy tűnik, ez nem egy érvényes email cím!";

        if (!err.emailError) {
            try {
                await auth.sendPasswordResetEmail(email);
                setSuccess(true);
            } catch (e) {
                if (e && e.code === 'auth/user-not-found') {
                    err.emailError = 'Ez az email nincs regisztrálva.'
                } else {
                    err.emailError = 'Hoppá, valami hiba történt. Kérlek, próbáld újra később!'
                }
            }
        }

        if (err.emailError) {
            setErrors(err);
            setShowErrors(true);
        }

        setLoading(false);
    }

    const onOkClicked = (evt)=>{
        evt.preventDefault();
        backToLogin();
    }

    const onCancelClicked = (evt)=>{
        evt.preventDefault();
        backToLogin();
    }

    return (
        <WideBox hasLogo={true} title={'Új jelszó beállítása'}>
            <Columns>
                <Column width={10} offset={1}>
                    { isSuccess
                        ? (<>
                            <InfoMessage>
                                Eddig oké! Légyszi, csekkold az inboxod, küldtünk egy email az új jelszó beállításához szükséges tudnivalókkal.
                            </InfoMessage>
                            <CenteredButtonsField>
                                <PrimaryButton onClick={onOkClicked}>
                                    Rendben
                                </PrimaryButton>
                            </CenteredButtonsField>
                        </>)

                        : (<>
                            <InfoMessage>
                                Add meg a bejelentkezési email címed és
                                kattints az <span className="is-family-code">Új jelszó</span> gombra.
                            </InfoMessage>
                            <form>
                                <fieldset disabled={isLoading}>
                                    <FormField name="Email"
                                               inputRef={inputRef}
                                               type="email"
                                               icon={faEnvelope}
                                               placeholder="peldabela@gmail.com"

                                               onChange={setEmail} error={showErrors && errors.emailError}
                                               isValid={email && !errors.emailError && validateEmail(email)}/>

                                    <CenteredButtonsField>
                                        <PrimaryButton isLoading={isLoading} onClick={onResetPasswordClicked}>
                                            Új jelszó
                                        </PrimaryButton>

                                        <SecondaryButton onClick={onCancelClicked}>
                                            Mégse
                                        </SecondaryButton>
                                    </CenteredButtonsField>
                                </fieldset>

                            </form>
                        </>)
                    }
                </Column>
            </Columns>
        </WideBox>
    );
}