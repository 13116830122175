
export class Location {

    constructor(id, ref, name, address, phone, email) {
        this.id=id;
        this.ref=ref;
        this.name=name;
        this.address=address;
        this.phone=phone;
        this.email=email;
    }

}