import React, { useContext, useEffect, useMemo, useState} from "react";

import Dropdown from "../../components/SimpleDropdownComponent";
import ScheduledEvent from "./ScheduledEvent";
import { UserContext } from "../../api/userContext";

import {listenEventChanges, listEvents, listLocations} from "../../api/admin";
import {Column, Columns} from "../../components/Layout";
import {
    addUserRegistrations,
    deleteUserRegistrations,
    getMyUserRegistrations,
    listenMyUserRegistrationChanges
} from "../../api/registration";
import {InProgressMsg} from "../../components/Progress";
import dayjs from "dayjs";

import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";


import '../../styles/RegistrationPage.sass';
import {Buttons, PrimaryButton} from "../../components/Buttons";
import {openCancelRegistrationForm} from "./Modals";


function createRegistration(event, user) {
    return addUserRegistrations(event);
}

export default () => {

    // ********************** Context variables **********************
    const { user } = useContext(UserContext);

    // ********************** State variables **********************
    const [isLoading, setLoading] = useState(true);
    const [isSaving, setSaving] = useState(false);
    const [enableRegistrationBtn, setEnableRegistrationBtn] = useState(false);

    const [locations, setLocations] = useState([]);
    const [events, setEvents] = useState([]);
    // const [eventClasses, setEventClasses] = useState([]);
    const [userRegistrations, setUserRegistrations] = useState([]);
    const [selectedEvents, setSelectedEvents] = useState([]);

    const [filters, setFilters] = useState({
        locationId: null,
        eventClassId: null
    })

    // ********************** Initialization **********************
    useEffect(() => {
        const fetchLocations = async () => {
            setLocations(await listLocations());
        }

        const fetchEvents = async () => {
            setEvents(await listEvents());
        }

        const fetchRegistrations = async () => {
            setUserRegistrations(await getMyUserRegistrations(user.uid));
        }

        Promise.all([
            // fetchEventClasses(),
            fetchLocations(),
            fetchEvents(),
            fetchRegistrations()
        ]).then(() => setLoading(false));

    }, [user.uid])

    useEffect(()=>{
        return listenMyUserRegistrationChanges(setUserRegistrations);
    }, []);
    //
    useEffect(()=>{
        return listenEventChanges(setEvents);
    }, []);

    useEffect(()=>{
        const registeredEventIds = userRegistrations.filter(r=>!!r.event).map(r=>r.event.id);
        setEnableRegistrationBtn( selectedEvents.some(se=>!registeredEventIds.includes(se.id)) );

    },[selectedEvents, userRegistrations])

    // ********************** Event Handlers **********************
    function onLocationFilterChange(locationId) {
        setFilters({
            locationId: locationId,
            eventClassId: null
        });
        setSelectedEvents([]);
    }

    const onToggleSelection = async (eventId) => {
        if (eventId && !selectedEvents.some(e=>e.id===eventId))
            setSelectedEvents((selectedEvents)=>selectedEvents.concat(events.find(e=>e.id===eventId)) )

        if (eventId && selectedEvents.some(e=>e.id===eventId))
            setSelectedEvents((selectedEvents)=>selectedEvents.filter(e=>e.id!==eventId));
    }

    const onRegisterClicked = async (evt) => {
        evt.preventDefault();

        const existingRegistrationEventIds = userRegistrations
            .filter(registration => !!registration.event)
            .map(registration => registration.event.id);

        const registerEvents = selectedEvents.filter(e=>!existingRegistrationEventIds.includes(e.id));

        if (registerEvents && registerEvents.length >0) {
            setSaving(true);
            Promise.all(registerEvents.map(evt=>createRegistration(evt, user)))
                .then((result)=>{
                    setSelectedEvents([]);
                    setTimeout(()=>setSaving(false), 800);
                });
        } else {
            console.log('No events to register for');
        }
    }

    const onCancelEventClicked = (eventId) => {

        const registration = userRegistrations.find(ur => ur.event && ur.event.id===eventId);

        if (registration)
         openCancelRegistrationForm(registration, ()=>{
             setSaving(true);
             return deleteUserRegistrations(registration.id).then(res=>{
                 setSelectedEvents([]);
                 setTimeout(()=>setSaving(false), 800);
             })
         })
    }

    // ********************** Creating render variables **********************
    const activeLocations = useMemo( ()=>[{
        id:null,
        name: "- Mind -"
    }].concat(locations),[locations]);

    const activeEvents = useMemo(()=>{
        return events
            .filter(evt=>evt.date && evt.eventClass && evt.eventClass.location)
            .filter(evt=>evt.date.isAfter( dayjs().add(-1, 'h') ))
            .filter(evt=>!filters.locationId || evt.eventClass.location.id===filters.locationId)
            .filter(evt=>!filters.eventClassId || evt.eventClass.id===filters.eventClassId)
            .sort((evt1,evt2)=>evt1.date.diff(evt2.date) > 0 ? 1: (evt1.date.diff(evt2.date) < 0 ? -1 : 0 ));
    }, [events, filters.locationId, filters.eventClassId]);


    // ********************** Render **********************
    return isLoading ? (<LoadingScreen/>) : (
        <RegistrationPageContainer>

                <div className="level">
                    <div className="level-right">
                        <div className="level-item ">

                            {
                                activeLocations ? (
                                    <div className="field mb-3">
                                        <Dropdown
                                            title="- Helyszínek -"
                                            options={activeLocations}
                                            onChange={onLocationFilterChange}
                                        />
                                    </div>) : null
                            }

                        </div>
                    </div>

                    <div className="level-left">
                        <div className="level-item">
                            <Buttons hasAddons={true} hidden={false}>
                                {enableRegistrationBtn
                                    ? (<PrimaryButton
                                        disabled={!enableRegistrationBtn || isSaving}
                                        onClick={onRegisterClicked}
                                        label={isSaving
                                            ? "Mentés folyamatban..."
                                            : "Jelentkezem a kijelöltekre"}
                                        icon={isSaving?null:faCheckDouble}/>)
                                    : null
                                }
                            </Buttons>
                        </div>
                    </div>
                </div>

                { activeEvents.length > 0 ?
                    ( <div className="mb-2" >
                        <p className="font-alt">Válassz ki egy vagy több szabad időpontot a jelentkezéshez!</p>
                    </div> ) : null
                }

                {
                    (activeEvents.length > 0) ?
                        activeEvents.map((cs,idx)=>{
                            const isRegistered = userRegistrations.some(ur=>ur.event?.id===cs.id);
                            return (<ScheduledEvent key={cs.id}
                                                    event={cs}
                                                    isDisabled={isSaving}
                                                    isSelected={selectedEvents.some(evt=>evt.id===cs.id)}
                                                    isRegistered={userRegistrations.some(ur=>ur.event?.id===cs.id)}
                                                    onClick={(eventId)=>(!isRegistered)?onToggleSelection(eventId):null}
                                                    onCancelClicked={()=>onCancelEventClicked(cs.id)}/>);
                    }) : (<p className="font-alt">Nem találok ilyen edzést</p>)
                }
        </RegistrationPageContainer>
    );
}

const RegistrationPageContainer = ({children})=>(
    <div className="registration-page">
        <section className="section ">
            <div className="container">
                <Columns>
                    <Column width={8} offset={2}>
                        {children}
                    </Column>
                </Columns>

            </div>
        </section>
    </div>)


const LoadingScreen = ()=>(<InProgressMsg msg={"Egy pillanat..."}/>)