import React, {useEffect, useState} from 'react';

import './App.sass';

import UserRoutes from "./ui/UserRoutes";
import {onAuthStateChange} from "./api/auth";
import {UserProvider} from "./api/userContext";
import * as userApi from "./api/user";



export default function () {
    const [ userContext, setUserContext ] = useState({ user: null, authStatus: 0 });

    useEffect( () => {

        const loadUserProfile = (user) => async () => {
            try {
                const userProfile = await userApi.retrieveUserProfile();

                const newUserContext = (userProfile)
                    ? Object.assign({
                        user: user, authStatus: 1,
                        reloadUserProfile: loadUserProfile(user)
                    }, userProfile  )
                    : {
                        user: user,
                        authStatus: 1,
                        status: 0,
                        reloadUserProfile: loadUserProfile(user)
                    };

                // console.debug('Setting UserContext', newUserContext);
                setUserContext(newUserContext);
            } catch (e) {
                console.error(e);
            }
        };

        const unsubscribe = onAuthStateChange(async (user)=>{
            if (user) {
                await loadUserProfile(user)();
            } else {
                setUserContext({user: null, authStatus: 1});
            }
        });
        return ()=>unsubscribe();
    },[]);


    return (
        <div className="App">
            <UserProvider value={userContext}>
                <UserRoutes />
            </UserProvider>
        </div>
    );
}
