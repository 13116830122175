import * as React from "react";

function SvgKettlebells(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path style={props.svgstyle} d="M365.295 169.167l23.743-45.191a84.618 84.618 0 00-2.484-83.117A84.617 84.617 0 00314.13 0H197.87a84.62 84.62 0 00-74.909 123.975l23.743 45.191C86.453 206.079 46.167 272.531 46.167 348.22c0 51.262 18.693 100.62 52.636 138.979a73.584 73.584 0 0055.078 24.8h204.24a73.578 73.578 0 0055.077-24.8c33.943-38.36 52.636-87.718 52.636-138.979-.001-75.688-40.288-142.14-100.539-179.053zM321.92 98.378l-23.308 44.363c-13.764-2.851-28.017-4.354-42.613-4.354s-28.849 1.502-42.613 4.354l-23.308-44.363a18.47 18.47 0 0116.348-27.057h99.148a18.466 18.466 0 0116.346 27.057z" />
    </svg>
  );
}

export default SvgKettlebells;
